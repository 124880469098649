import React, { useState, useEffect } from "react";
import TalkvioConfig from "../talkvio.json";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GoogleLogin = ({ children, onLogin }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const openGoogleLoginWindow = () => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    logT(
      "google",
      "open google login window redirectURI",
      window.location.origin + "/googlelogin",
    );

    const googleAuthUrl =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${TalkvioConfig.google_client_id}` +
      `&redirect_uri=${encodeURIComponent(window.location.origin + "/googlelogin")}` +
      `&response_type=id_token` +
      `&scope=email profile` +
      `&nonce=${Math.random().toString(36).substring(2, 15)}` +
      `&prompt=select_account`;

    const popup = window.open(
      googleAuthUrl,
      "Google Login",
      `width=${width},height=${height},left=${left},top=${top}`,
    );

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      logT("google", "popup blocked, redirecting in same window");
      window.location.href = googleAuthUrl;
    }
  };

  const googleSignIn = () => {
    if (window.google) {
      setIsLoggingIn(true);
      window.google.accounts.id.initialize({
        client_id: TalkvioConfig.google_client_id,
        callback: onLogin,
      });
      window.google.accounts.id.prompt((notification) => {
        logT(
          "google",
          "Google login prompt type",
          notification.getMomentType(),
          notification.isSkippedMoment()
            ? "failed skipped reason: " + notification.getSkippedReason()
            : "",
        );
        setIsLoggingIn(false);
        if (notification.isSkippedMoment()) {
          logT(
            "google",
            "skipped prompt, user will be redirected to google login window",
          );
          openGoogleLoginWindow();
        }
      });
    } else if (
      typeof window.TalkvioAndroid != "undefined" &&
      !window.googleToken
    ) {
      logT("android", "login with googleToken");
      window.TalkvioAndroid.googleLogin();
    } else {
      logTE(
        "google",
        "google browser api is unavailable, opening google login window",
      );
      openGoogleLoginWindow();
    }
  };

  useEffect(() => {
    if (window.googleToken) {
      logT("android", "login with provided google token", window.googleToken);
      onLogin({ credential: window.googleToken });
    }
  }, []);

  return isLoggingIn ? (
    <FontAwesomeIcon className="login-spinner" icon={faSpinner} spin />
  ) : (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        googleSignIn();
      }}
    >
      {children}
    </div>
  );
};
export default GoogleLogin;
