import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { LoginContext } from "./Login";

const ConfirmPassword = () => {
  const { doLogin } = useContext(LoginContext);

  const fetchData = async () => {
    const url = new URL(window.location.href);
    logT("google", "google auth page", url);
    const idToken =
      url.searchParams.get("id_token") ||
      url.hash.split("id_token=")[1]?.split("&")[0];
    logT("google", "idToken", idToken);
    if (!idToken) return;
    doLogin({ credential: idToken });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="confirm">
      {__(
        "You are loggined with your google account, you can close this page and update refresh original page",
      )}
    </div>
  );
};
export default ConfirmPassword;
