// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faBold,
  faGear,
  faImage,
  faItalic,
  faLink,
  faList,
  faQuoteLeft,
  faSmile,
  faStrikethrough,
  faTextSlash,
  faTrash,
  faUnderline,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import Input from "./Input";
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { imageData, videoData, dataURItoBlob } from "../global/FileData";
import Checkbox from "./Checkbox";
import InputImage from "./InputImage";
import Tooltip from "./Tooltip";
import DefaultAvatar_150 from "../default-avatar_150.png";
import InputVideo from "./InputVideo";
import { LoginContext } from "./Login";
import {
  getStorageValue,
  setStorageValue,
  clearStorageValue,
} from "../global/Storage";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { getClosestParentByClass, isContainTag } from "../global/Html";
import RelativeContainer from "./RelativeContainer";
import ClickOutsideContainer from "./ClickOutsideContainer";
import { isMobile } from "../global/Global";
const DOMPurify = require("dompurify")(window);

function setEndOfContenteditable(contentEditableElement) {
  var range, selection;
  if (document.createRange) {
    //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  } else if (document.selection) {
    //IE 8 and lower
    range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    range.select(); //Select the range (make it the visible selection
  }
}

const SmileMenu = ({ insertTextInEditor, setShowSmileMenu, showSmileMenu }) => {
  return (
    <ClickOutsideContainer isShow={showSmileMenu} setIsShow={setShowSmileMenu}>
      <RelativeContainer>
        <ul className="smileMenu">
          {(() => {
            const smiles = [];
            for (let s = 0x1f600; s < 0x1f64f; s++) {
              smiles.push(
                <li
                  key={s}
                  onClick={() => {
                    const smile = String.fromCodePoint(s);
                    logT("smile", "smile insert", smile);
                    insertTextInEditor(smile);
                  }}
                >
                  {String.fromCodePoint(s)}
                </li>,
              );
            }
            return smiles;
          })()}
        </ul>
      </RelativeContainer>
    </ClickOutsideContainer>
  );
};

const Text = ({
  block,
  onChange,
  onPaste,
  onPreChange,
  minimal = false,
  minimalCollapsed = true,
  textHintMinimal = null,
  editorSource = null,
}) => {
  const editor = useRef();
  const [userNamesList, setUserNamesList] = useState(null);
  const [showSmileMenu, setShowSmileMenu] = useState(false);
  const { user } = useContext(LoginContext);

  const [isBold, setIsBold] = useState(document.queryCommandState("bold"));
  const [isItallic, setIsItallic] = useState(
    document.queryCommandState("italic"),
  );
  const [isUnderline, setIsUnderline] = useState(
    document.queryCommandState("underline"),
  );
  const [isStrike, setIsStrike] = useState(
    document.queryCommandState("strikeThrough"),
  );

  function performAction(command) {
    window.document.execCommand(command, false, null);
    editor.current.focus();
  }

  const ignoreNameList = [];
  let searchUserTimeout;

  const setEditorHtml = (html) => {
    if (!editor?.current) return;

    if (!editor.current.innerHTML) return;

    editor.current.innerHTML = html;

    const noteditables = editor.current.querySelectorAll(".noteditable");
    for (let i = 0; i < noteditables.length; i++) {
      const noteditable = noteditables[i];
      noteditable.contentEditable = false;
      const nextElement = noteditable.nextElementSibling;
      if (!nextElement || !nextElement?.classList?.contains("space-deleter")) {
        const spaceDeleter = document.createElement("span");
        spaceDeleter.className = "talkvio-element space-deleter";
        spaceDeleter.innerHTML = "&#8203;";
        noteditable.insertAdjacentElement("afterend", spaceDeleter);
      } else {
        logT("editor", "space deleter next");
      }
    }
  };

  const insertTextInEditor = (insertText) => {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);

      const commonAncestor = range.commonAncestorContainer;
      if (!editor.current.contains(commonAncestor)) {
        editor.current.appendChild(document.createTextNode(insertText));
        save();
        return;
      }

      range.deleteContents(); // Remove selected text, if any
      const textNode = document.createTextNode(insertText);
      range.insertNode(textNode);

      logT("insert", "insert text into editor", insertText);

      // move to text after
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
      save();
    } else {
      editor.current.appendChild(document.createTextNode(insertText));
      save();
    }
  };

  const insertUserName = async (search, userid = null) => {
    let data;
    if (userid) {
      data = await window.TALKVIOAPI("user", {
        userid,
      });
    } else if (search) {
      data = await window.TALKVIOAPI("user", {
        search,
      });
    }
    if (data?.user) {
      logT(
        "ask",
        "match editor username",
        search || userid,
        "html =",
        editor.current.innerHTML,
      );
      const toUserDiv = document.createElement("span");
      toUserDiv.className = "talkvio-element ask-user noteditable";
      toUserDiv.contentEditable = false;
      const usernameText = document.createTextNode(data.user.username);
      const userTextSpan = document.createElement("span");
      userTextSpan.className = "username";

      const idTextMeta = document.createElement("input");
      idTextMeta.setAttribute("type", "hidden");
      idTextMeta.setAttribute("name", "userid");
      idTextMeta.setAttribute("value", data.user.userid);
      toUserDiv.appendChild(idTextMeta);

      const avatar = document.createElement("div");
      avatar.className = "avatar";
      const avatarImg = document.createElement("img");
      avatarImg.src =
        data.user.avatarrevision > 0
          ? `https://talkvio.com/customavatars/avatar${data.user.userid}_${data.user.avatarrevision}.gif`
          : DefaultAvatar_150;
      avatarImg.alt = `Avatar of user ${data.user.username}`;
      avatar.appendChild(avatarImg);

      userTextSpan.appendChild(usernameText);
      toUserDiv.appendChild(avatar);
      toUserDiv.appendChild(userTextSpan);

      setEditorHtml(
        editor.current.innerHTML.replace(
          "@" + search,
          toUserDiv.outerHTML + ", ",
        ),
      );

      setEndOfContenteditable(editor.current);
      setUserNamesList(null);
    } else if (data?.users) {
      setUserNamesList({
        users: data.users,
        search,
      });
    } else {
      ignoreNameList.push(search);
      setUserNamesList(null);
    }
  };

  const UserNameList = ({ users, search }) => {
    return (
      <ul className="userNameList">
        {users.map((u, i) => (
          <li
            key={i}
            onClick={() => {
              setUserNamesList(null);
              insertUserName(search, u.userid);
            }}
          >
            <div className="avatar">
              <img
                src={
                  u.avatarrevision > 0
                    ? `https://talkvio.com/customavatars/avatar${u.userid}_${u.avatarrevision}.gif`
                    : DefaultAvatar_150
                }
              />
            </div>
            <div className="username">{u.username}</div>
          </li>
        ))}
      </ul>
    );
  };

  const save = () => {
    if (onChange) onChange(editor.current.innerHTML);
  };

  useEffect(() => {
    const observer = new MutationObserver((mutation) => {
      const removeElement = mutation[0]?.removedNodes?.[0];
      if (removeElement?.classList?.contains("space-deleter")) {
        const previousElement = mutation[0]?.previousSibling;
        if (
          previousElement?.classList?.contains("noteditable") ||
          previousElement?.classList?.contains("deletable")
        ) {
          logT(
            "noteditable",
            "remove previus not editable elment on space deleter",
            previousElement,
          );
          previousElement.remove();
        }
      } else if (
        removeElement?.classList?.contains("noteditable") ||
        removeElement?.classList?.contains("deletable")
      ) {
        const nextElement = mutation[0]?.nextSibling;
        if (nextElement?.classList?.contains("space-deleter")) {
          logT("noteditable", "remove mext space deleter", nextElement);
          nextElement.remove();
        }
      }
    });
    observer.observe(editor.current, { childList: true });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const selectionListener = (e) => {
      const selection = window.getSelection();
      if (!selection?.rangeCount) return;
      const range = selection.getRangeAt(0);
      if (!range) return;
      const selectedElement = range.commonAncestorContainer;
      if (!editor?.current?.contains(selectedElement)) return;

      // If the selection is in a text node, get its parent element
      const element =
        selectedElement.nodeType === Node.TEXT_NODE
          ? selectedElement.parentNode
          : selectedElement;

      if (!element) return;

      if (isContainTag(element, "b", editor.current)) {
        setIsBold(true);
      } else {
        setIsBold(false);
      }

      if (isContainTag(element, "i", editor.current)) {
        setIsItallic(true);
      } else {
        setIsItallic(false);
      }

      if (isContainTag(element, "u", editor.current)) {
        setIsUnderline(true);
      } else {
        setIsUnderline(false);
      }

      if (isContainTag(element, "strike", editor.current)) {
        setIsStrike(true);
      } else {
        setIsStrike(false);
      }
    };
    document.addEventListener("selectionchange", selectionListener);
    return () => {
      document.removeEventListener("selectionchange", selectionListener);
    };
  }, [editor]);

  return (
    <div
      className={
        "block-editor" + (minimal && minimalCollapsed ? " minimal" : "")
      }
    >
      {!minimal || !minimalCollapsed ? (
        <div
          className={
            "block-text-controls" +
            (editorSource != "stickyEditor" ? " header-related" : "")
          }
        >
          <button
            className={isBold ? "auto selected" : "auto"}
            onClick={() => {
              performAction("bold");
              setIsBold(document.queryCommandState("bold"));
            }}
          >
            <FontAwesomeIcon icon={faBold} />
          </button>
          <button
            className={isItallic ? "auto selected" : "auto"}
            onClick={() => {
              performAction("italic");
              setIsItallic(document.queryCommandState("italic"));
            }}
          >
            <FontAwesomeIcon icon={faItalic} />
          </button>
          <button
            className={isUnderline ? "auto selected" : "auto"}
            onClick={() => {
              performAction("underline");
              setIsUnderline(document.queryCommandState("underline"));
            }}
          >
            <FontAwesomeIcon icon={faUnderline} />
          </button>
          <button
            className={isStrike ? "auto selected" : "auto"}
            onClick={() => {
              performAction("strikeThrough");
              setIsStrike(document.queryCommandState("strikeThrough"));
            }}
          >
            <FontAwesomeIcon icon={faStrikethrough} />
          </button>
          <Tooltip
            hint={__(
              "Quote text. You can also select text of specific post and press quote while it selected.",
            )}
          >
            <button
              onClick={() => {
                const selection = window.getSelection();
                if (selection && selection.toString().length > 0) {
                  const selectedText = selection.toString();
                  const selectedElement = selection.anchorNode;
                  let username;
                  let userid;
                  let avatarrevision;
                  const post = getClosestParentByClass(selectedElement, "post");
                  if (post) {
                    if (
                      getClosestParentByClass(selectedElement, "article") &&
                      !getClosestParentByClass(selectedElement, "post-quote") &&
                      !getClosestParentByClass(selectedElement, "message-quote")
                    ) {
                      username = post.querySelector(
                        'input[name="username"]',
                      )?.value;
                      userid = post.querySelector(
                        'input[name="userid"]',
                      )?.value;
                      avatarrevision = post.querySelector(
                        'input[name="avatarrevision"]',
                      )?.value;
                    }
                  }
                  const textNode = document.createTextNode(selectedText);

                  const quoteDiv = document.createElement("div");
                  const quoteDivDeep = document.createElement("div");
                  const quoteDivText = document.createElement("div");
                  quoteDiv.className =
                    "message-quote talkvio-element deletable";
                  quoteDivDeep.className = "message-quote-deep";
                  quoteDivText.className = "message-quote-text";
                  if (username) {
                    const usernameDiv = document.createElement("a");
                    usernameDiv.setAttribute("href", "/user/" + userid);
                    const avatar = document.createElement("img");
                    avatar.setAttribute(
                      "src",
                      avatarrevision > 0
                        ? `/customavatars/avatar${userid}_${avatarrevision}.gif`
                        : `/files/default-avatar_150.png`,
                    );
                    avatar.setAttribute("alt", `avatar of ${username}`);
                    const usernameSpan = document.createElement("span");
                    usernameSpan.className = "username";
                    const usernameText = document.createTextNode(username);
                    usernameSpan.appendChild(usernameText);
                    usernameDiv.className = "message-quote-to";
                    usernameDiv.appendChild(avatar);
                    usernameDiv.appendChild(usernameSpan);
                    quoteDivDeep.appendChild(usernameDiv);
                    quoteDiv.contentEditable = false;
                    quoteDiv.className += " noteditable";
                  }
                  quoteDivText.appendChild(textNode);
                  quoteDivDeep.appendChild(quoteDivText);
                  quoteDiv.appendChild(quoteDivDeep);
                  editor.current.appendChild(quoteDiv);

                  // inser empty block after
                  const spaceDeleter = document.createElement("span");
                  spaceDeleter.className = "talkvio-element space-deleter";
                  spaceDeleter.innerHTML = "&#8203;";
                  quoteDiv.insertAdjacentElement("afterend", spaceDeleter);
                  save();
                }
              }}
            >
              <FontAwesomeIcon icon={faQuoteLeft} />
            </button>
          </Tooltip>
          <Tooltip hint={__("Create list or convert selected text to list")}>
            <button
              onClick={() => {
                const selection = window.getSelection();
                if (selection && selection.toString().length > 0) {
                  const selectedText = selection.toString();
                  const lines = selectedText
                    .split("\n")
                    .filter((line) => line.trim());

                  const listHtml = `<ul>${lines.map((line) => `<li>${line}</li>`).join("")}</ul>`;

                  document.execCommand("insertHTML", false, listHtml);
                } else {
                  document.execCommand(
                    "insertHTML",
                    false,
                    "<ul><li></li></ul>",
                  );

                  const range = document.createRange();
                  const li = editor.current.querySelector("ul:last-child li");
                  range.setStart(li, 0);
                  range.collapse(true);
                  selection.removeAllRanges();
                  selection.addRange(range);
                }
                save();
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
          </Tooltip>
          <button
            onClick={() => {
              const selection = window.getSelection();
              if (selection && selection.toString().length > 0) {
                const selectedText = selection.toString();
                const selectedElement = selection.anchorNode;
                if (!getClosestParentByClass(selectedElement, "block-text")) {
                  logT("editor", "outside from editor");
                  return;
                }

                const userInput = window.prompt(__("Enter the link") + ":");
                if (!userInput) {
                  return;
                }

                const linkElement = document.createElement("a");
                linkElement.href = userInput;
                linkElement.textContent = selectedText;

                const range = selection.getRangeAt(0);
                range.deleteContents();
                range.insertNode(linkElement);
                save();
              }
            }}
          >
            <FontAwesomeIcon icon={faLink} />
          </button>
          <Tooltip
            hint={__(
              "Remove formating. Pressing this button the text inside text block will remove any formating and will be changed to default",
            )}
          >
            <button
              onClick={() => {
                const selection = window.getSelection();
                if (selection && selection.toString().length > 0) {
                  if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    const text = range.toString();
                    var newNode = document.createTextNode(text);
                    range.deleteContents();
                    range.insertNode(newNode);
                  }
                } else {
                  const text = editor.current.innerText;
                  editor.current.innerHTML = text
                    .replace(/\n\n/g, "\n")
                    .split("\n")
                    .map((el) =>
                      el ? `<div>${el}</div>` : `<div><br /></div>`,
                    )
                    .join("\n");
                }
              }}
            >
              <FontAwesomeIcon icon={faTextSlash} />
            </button>
          </Tooltip>
          <button
            className="smileChooseButton"
            onClick={(e) => {
              e.stopPropagation();
              setShowSmileMenu(!showSmileMenu);
              if (showSmileMenu) logT("smile", "hide smile menu");
              else logT("smile", "show menu");
            }}
          >
            <FontAwesomeIcon icon={faSmile} />
            {showSmileMenu ? (
              <SmileMenu
                insertTextInEditor={insertTextInEditor}
                showSmileMenu={showSmileMenu}
                setShowSmileMenu={setShowSmileMenu}
              />
            ) : null}
          </button>
        </div>
      ) : null}
      <div className="block-text">
        <div
          ref={editor}
          onFocus={() => {
            logT("editor", "focus editor, block all contenteditable");

            if (!editor?.current) return;

            const noteditables =
              editor.current.querySelectorAll(".noteditable");
            for (let i = 0; i < noteditables.length; i++) {
              noteditables[i].contentEditable = false;
            }
          }}
          onBlur={(event) => {
            if (
              event.relatedTarget?.tagName?.toLowerCase() == "button" &&
              getClosestParentByClass(event.relatedTarget, "block-editor")
            ) {
              logT("editor", "text", "ignore blur event");
              return;
            }
            if (onChange) onChange(event.target.innerHTML);
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(block.data) || "",
          }}
          className="editor"
          data-hint={
            user?.posts < 5
              ? __("Your can start to enter") +
                " " +
                __("post/comment") +
                " " +
                __("here") +
                ". " +
                __(
                  "You can ask for tehnical help of site/app usage help by entering appeal to the user @help. You can also call the moderation with @moderator or admin by @admin",
                ) +
                ". " +
                (!isMobile()
                  ? __("Drag & drop of media and ctrl+v is also worked here") +
                    ". "
                  : "")
              : null
          }
          data-hint-minimal={textHintMinimal || __("Please enter your comment")}
          contentEditable={true}
          onInput={async (event) => {
            if (onPreChange) onPreChange(event.target.innerHTML);

            const text = event.target.innerText;
            if (searchUserTimeout) clearTimeout(searchUserTimeout);
            searchUserTimeout = setTimeout(async () => {
              let userNameMatch = /(?:^|\s|,)@([^\s,]+)/g;
              let match;
              while ((match = userNameMatch.exec(text)) !== null) {
                if (ignoreNameList.includes(match[1])) {
                  continue;
                }
                insertUserName(match[1]);
              }
            }, 310);
          }}
          onPaste={(event) => {
            if (onPaste) onPaste(event);

            const clipboardData = event.clipboardData || window.clipboardData;
            if (clipboardData.types.includes("text/html")) {
              let html = clipboardData.getData("text/html");
              logT("parse", "text before parse", html);

              if (!html) {
                logT("parse", "no text parse");
                return;
              }

              const tempDiv = document.createElement("div");
              tempDiv.innerHTML = html;

              const allowedTags = [
                "a",
                "br",
                "span",
                "div",
                "p",
                "b",
                "i",
                "u",
                "s",
                "strike",
                "ul",
                "li",
              ];

              const classesWhitelist = [
                "ask-user",
                "noteditable",
                "deletable",
                "username",
                "userid",
                "message-quote",
                "message-quote-to",
              ];

              const attributesWhitelist = ["href", "class"];

              function renameTag(target, typeNode) {
                let newNode = document.createElement(typeNode || "span");

                // copy attributes
                for (let i = 0; i < target.attributes.length; i++) {
                  newNode.setAttribute(
                    target.attributes[i].nodeName,
                    target.attributes[i].nodeValue,
                  );
                }

                // move children. firstChild is a live API so we can 'while' on that
                while (target.firstChild) {
                  newNode.appendChild(target.firstChild);
                }

                // copy in-line styles
                if (target.style.length > 0) {
                  newNode.style.cssText = target.style.cssText;
                }

                return target.parentNode.replaceChild(newNode, target);
              }

              // parsing
              let ignoreElements = [];
              const allElements = tempDiv.getElementsByTagName("*");
              for (const element of allElements) {
                const tag = element.tagName.toLowerCase();

                if (
                  tag == "input" &&
                  element.hasAttribute("type") &&
                  element.getAttribute("type") == "hidden"
                ) {
                  logT("parse", "skip input", tag, "because of hidden");
                  continue;
                }
                if (
                  element.hasAttribute("class") &&
                  element.classList?.contains("talkvio-element")
                ) {
                  logT("parse", "skip", tag, "because of talkvio-element");
                  ignoreElements = ignoreElements.concat(
                    Array.from(element.getElementsByTagName("*")),
                  );
                  continue;
                }
                if (ignoreElements.find((el) => el == element)) {
                  logT(
                    "parse",
                    "skip",
                    tag,
                    "because of talkvio-element child",
                  );
                  continue;
                }

                let removeAttribs = [];
                for (const attribute of element.attributes) {
                  const attributeName = attribute.name.toLowerCase();
                  if (attributesWhitelist.includes(attributeName)) {
                    continue;
                  }
                  removeAttribs.push(attributeName);
                }
                removeAttribs.forEach((attr) => {
                  element.removeAttribute(attr);
                  logT("parse", "erase attribute", attr, "from tag", tag);
                });
                if (element.hasAttribute("class")) {
                  element.setAttribute(
                    "class",
                    element
                      .getAttribute("class")
                      .split(" ")
                      .filter((cls) => classesWhitelist.includes(cls))
                      .join(" "),
                  );
                  if (
                    element.hasAttribute("class") &&
                    element.getAttribute("class") == ""
                  ) {
                    element.removeAttribute("class");
                  }
                }

                if (!allowedTags.includes(tag)) {
                  logT("parse", "rename tag", tag, "to", "span");
                  renameTag(element, "span");
                }
              }

              html = tempDiv.innerHTML.trim().replace(/\n/g, "<br />");

              logT("parse", "text after parse", html);
              event.preventDefault();
              document.execCommand("insertHTML", false, html);

              const noteditables =
                editor.current.querySelectorAll(".noteditable");
              for (let i = 0; i < noteditables.length; i++) {
                noteditables[i].contentEditable = false;
              }
            }
          }}
        ></div>
      </div>
      {userNamesList?.users?.length > 0 ? (
        <UserNameList
          users={userNamesList.users}
          search={userNamesList.search}
        />
      ) : null}
    </div>
  );
};

const Image = ({ block, userid, onChange }) => {
  const [imageUrl, setImageUrl] = useState(
    !block.imageUrl && block.data
      ? window.URL.createObjectURL(dataURItoBlob(block.data))
      : block.imageUrl,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (block.imageUrl) {
        setImageUrl(block.imageUrl);
      } else if (!block.imageUrl && block.data) {
        block.imageUrl = window.URL.createObjectURL(dataURItoBlob(block.data));
        setImageUrl(block.imageUrl);
      } else if (!block.imageUrl && block.storageFile) {
        block.imageUrl = window.URL.createObjectURL(
          dataURItoBlob(await getStorageValue(block.storageFile)),
        );
        setImageUrl(block.imageUrl);
      } else {
        setImageUrl(null);
      }
    };
    fetchData();
  }, [block, block.data, block.imageUrl, block.storageFile]);

  return (
    <div className="block-image">
      <InputImage
        multiple={true}
        onFiles={(files) => {
          if (files.length == 0) {
            return;
          }

          for (const file of files) {
            if (file.size > 25 * 1024 * 1024) {
              window.showError(
                __("Error"),
                __("Max limit size for images") +
                  " 25mb. " +
                  __("Please reduce image size and try again."),
              );
              return;
            }
          }

          if (onChange) onChange(files);
        }}
      >
        {block.file && userid
          ? (process.env.NODE_ENV == "production"
              ? ""
              : "https://talkvio.com") + `/file/${userid}/${block.file}`
          : imageUrl}
      </InputImage>
      <Input
        inputClassName="w100p"
        placeholderLeft={false}
        placeholder={__(
          "Enter description or annotation for picture (optional)",
        )}
        value={block.description}
        onBlur={(value) => {
          block.description = value;
          if (onChange) onChange();
        }}
      />
    </div>
  );
};

const Video = ({ block, userid, onChange }) => {
  const { user } = useContext(LoginContext);

  const [videoUrl, setVideoUrl] = useState(
    (block.type == "video" || block.type == "animation") &&
      !block.videoUrl &&
      block.data &&
      !block.file
      ? window.URL.createObjectURL(dataURItoBlob(block.data))
      : block.videoUrl,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (block.type == "video" || block.type == "animation") {
        if (block.videoUrl) {
          setVideoUrl(block.videoUrl);
        } else if (!block.videoUrl && block.data) {
          block.videoUrl = window.URL.createObjectURL(
            dataURItoBlob(block.data),
          );
          setVideoUrl(block.videoUrl);
        } else if (!block.videoUrl && block.storageFile) {
          block.videoUrl = window.URL.createObjectURL(
            dataURItoBlob(await getStorageValue(block.storageFile)),
          );
          setVideoUrl(block.videoUrl);
        } else {
          setVideoUrl(null);
        }
      }
    };
    fetchData();
  }, [block, block.data, block.videoUrl, block.storageFile]);

  return (
    <div className="block-video">
      {block.type == "youtube" && block.data ? (
        <iframe
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          width="100%"
          height="100%"
          src={"https://www.youtube.com/embed/" + block.data}
        />
      ) : null}
      {block.type == "vk" && block.data && block.data.oid && block.data.id ? (
        <iframe
          width="853"
          height="480"
          src={`//vk.com/video_ext.php?oid=${block.data.oid}&id=${block.data.id}&hash=118cd8b378ffa95e&hd=1`}
          frameBorder="0"
          allowFullScreen="1"
        />
      ) : null}
      {block.type == "twitch" && block.data ? (
        <iframe
          src={`https://player.twitch.tv/?${block.data?.video ? "video=" + block.data?.video : ""}${block.data?.channel ? "channel=" + block.data?.channel : ""}&parent=talkvio.com`}
          width="100%"
          height="100%"
          allowFullScreen="1"
        ></iframe>
      ) : null}
      {block.type == "coub" && block.data ? (
        <iframe
          src={`https://coub.com/embed/${block.data}`}
          width="100%"
          height="100%"
          allowFullScreen="1"
          allow="encrypted-media;"
        ></iframe>
      ) : null}
      {block.type == "rutube" && block.data ? (
        <iframe
          src={`https://rutube.ru/play/embed/${block.data}`}
          width="100%"
          height="100%"
          allowFullScreen="1"
        ></iframe>
      ) : null}
      {block.type == "video" || block.type == "animation" ? (
        <>
          {block.file && userid ? (
            <>
              {block.processing ? (
                <div className="video-preview-processing">
                  <img
                    src={
                      (process.env.NODE_ENV == "production"
                        ? ""
                        : "http://localhost:9989") +
                      "/file/thumbnail/" +
                      userid +
                      "/" +
                      block.file
                    }
                    alt={"video processing"}
                  />
                  <div className="processing-text">
                    {__(
                      "Video in proccessing state, please wait until it finished",
                    )}
                  </div>
                </div>
              ) : (
                <video
                  controls={true}
                  poster={
                    (process.env.NODE_ENV == "production"
                      ? ""
                      : "http://localhost:9989") +
                    "/file/thumbnail/" +
                    userid +
                    "/" +
                    block.file
                  }
                >
                  <source
                    src={
                      (process.env.NODE_ENV == "production"
                        ? ""
                        : "http://localhost:9989") +
                      "/file/" +
                      userid +
                      "/" +
                      block.file
                    }
                  />
                </video>
              )}
            </>
          ) : videoUrl && !block.file ? (
            <Fragment key={videoUrl}>
              <video controls={true}>
                <source
                  src={
                    videoUrl +
                    (window.TalkvioAndroid != "undefined" ? "#t=0.01" : "")
                  }
                />
              </video>
            </Fragment>
          ) : null}
        </>
      ) : null}
      {!block.data && !block.storageFile && !block.file && !videoUrl ? (
        <div className="w100p">
          <div className="enterLink row flex center-column">
            {__("Enter the link for youtube, vk, twitch, coub") + ", rutube"}:
            <input
              type="text"
              onChange={(event) => {
                if (onChange && event.target.value.length > 0) {
                  let link = event.target.value;
                  let twitchMatch = link.match(
                    /.*(?:twitch\.tv)\/(?:videos\/([0-9]+)|([0-9A-Za-z]+))/,
                  );
                  if (twitchMatch && (twitchMatch[1] || twitchMatch[2])) {
                    onChange(
                      twitchMatch[1]
                        ? { video: twitchMatch[1] }
                        : { channel: twitchMatch[2] },
                      "twitch",
                    );
                    return;
                  }
                  let rutubeMatch = link.match(
                    /.*(?:rutube\.ru)\/(?:video|shorts)\/([0-9A-Za-z]+)/,
                  );
                  if (rutubeMatch && rutubeMatch[1]) {
                    onChange(rutubeMatch[1], "rutube");
                    return;
                  }
                  let youtubeMatch = link.match(
                    /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|live\/|watch\?v=)([^#\&\?]*).*/,
                  );
                  if (youtubeMatch && youtubeMatch[1]) {
                    onChange(youtubeMatch[1], "youtube");
                    return;
                  }
                  let vkMatch = link.match(
                    /.*(?:vk\.com).*video(\-?[0-9]+)_([0-9]+)/,
                  );
                  if (vkMatch && vkMatch[1] && vkMatch[2]) {
                    onChange({ oid: vkMatch[1], id: vkMatch[2] }, "vk");
                    return;
                  }
                  let coubMatch = link.match(
                    /.*(?:coub\.com)\/view\/([0-9A-Za-z]+)/,
                  );
                  if (coubMatch && coubMatch[1]) {
                    onChange(coubMatch[1], "coub");
                    return;
                  }
                }
              }}
            ></input>
          </div>
          {user?.karma >= 4 && user?.userpoints >= 5 ? (
            <InputVideo
              onFiles={(files) => {
                if (files[0].size > 200 * 1024 * 1024) {
                  window.showError(
                    __("Error"),
                    __("Max limit size for videos") +
                      " 250mb. " +
                      __("Please reduce image size and try again."),
                  );
                  return;
                }
                if (onChange) onChange(files[0], "video");
              }}
            ></InputVideo>
          ) : (
            <div className="videoUpload locked">
              <div className="videoIcon">
                <FontAwesomeIcon icon={faVideo} />
              </div>
              <div className="imageText">
                {__("You need") +
                  " 4 " +
                  __("karma(s)") +
                  " " +
                  __("and") +
                  " 5 " +
                  __("user point(s)") +
                  " " +
                  __("to upload own video files")}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const dataParse = async (data, fullCompile = false) => {
  const obj = Object.assign({}, data);
  if (obj.blocks && obj.blocks.length > 0) {
    // copy blocks
    obj.blocks = obj.blocks.map((block) => Object.assign({}, block));
    obj.blocks = await Promise.all(
      obj.blocks.map(async (block) => {
        if (block.type == "image") {
          if (fullCompile) {
            if (block.storageFile)
              block.data = await getStorageValue(block.storageFile);
            else if (block.data) block.data = await imageData(block.data);
            else if (block.imageUrl && !block.file)
              block.data = await imageData(
                await (await fetch(block.imageUrl)).blob(),
              );
            delete block.storageFile;
          }
          delete block.imageUrl;
        }
        if (block.type == "video" || block.type == "animation") {
          if (fullCompile) {
            if (block.storageFile)
              block.data = await getStorageValue(block.storageFile);
            else if (block.data) block.data = await videoData(block.data);
            else if (block.videoUrl && !block.file)
              block.data = await videoData(
                await (await fetch(block.videoUrl)).blob(),
              );
            delete block.storageFile;
          }
          delete block.videoUrl;
        }
        return block;
      }),
    );
  }
  return obj;
};

export default ({
  data,
  setData,
  onPreData,
  temporary,
  minimal = false,
  minimalCollapsed = true,
  textHintMinimal = null,
  editorSource = null,
}) => {
  const { user } = useContext(LoginContext);

  if (data && !data.blocks) data.blocks = [{ type: "text", data: "" }];

  if (data && data.blocks.length == 0)
    data.blocks.push({ type: "text", data: "" });

  const changeIndex = (index, direction) => {
    const blocks = data.blocks;
    if (blocks.length == 0) return;
    if (index < 0 || index >= blocks.length) return;
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= blocks.length) return;

    const temp = blocks[index];
    blocks[index] = blocks[newIndex];
    blocks[newIndex] = temp;
    logT("editor", "changeIndex", index, direction);
    setData(Object.assign({}, data));
  };

  const addBlock = (index, type) => {
    const element = {
      type,
      data: null,
    };
    if (index > data.blocks.length) return;

    data.blocks.splice(index + 1, 0, element);
    setData(Object.assign({}, data));
  };

  const removeBlock = (index) => {
    if (index == 0 && data.blocks.length <= 1) {
      setData({ blocks: [{ type: "text", data: "" }] });
      return;
    }
    const block = data.blocks[index];
    if (block.storageFile) {
      logT("storage", "remove also file from storage", block.storageFile);
      clearStorageValue(block.storageFile);
    }
    data.blocks.splice(index, 1);
    setData(Object.assign({}, data));
  };

  const currentFocusedBlock = useRef(-1);

  const insertAfterFocus = (block, index = 0) => {
    if (!data.blocks) {
      return;
    }

    if (currentFocusedBlock?.current >= 0) {
      if (currentFocusedBlock.current >= data.blocks.length) {
        currentFocusedBlock.current = Math.max(data.blocks.length - 1, 0);
      }
      logT(
        "editor",
        "insert new block at",
        currentFocusedBlock.current + 1 + index,
      );
      data.blocks.splice(currentFocusedBlock.current + 1 + index, 0, block);
    } else {
      logT("editor", "insert new block at the end");
      data.blocks.push(block);
    }
  };

  const processFiles = (files) => {
    let changed = false;
    const processFilesList = [];
    let index = 0;

    if (files.length > 1 && user?.settings?.editorFilesSortStable) {
      files = Array.from(files).sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      logT("editor", "sorted files", files.length, "from A to Z");
    }

    for (const file of files) {
      if (file.type.startsWith("image/")) {
        if (file.size > 25 * 1024 * 1024) {
          window.showError(
            __("Error"),
            __("Max limit size for images") +
              " 25mb. " +
              __("Please reduce image size and try again."),
          );
          continue;
        }
        const addBlock = async () => {
          const block = { type: "image" };
          const randomFileName =
            "file:" +
            (Math.random() + 1).toString(36).substring(7) +
            (Math.random() + 1).toString(36).substring(7);
          if (!temporary) {
            await setStorageValue(randomFileName, await imageData(file), true);
            block.storageFile = randomFileName;
          }
          block.imageUrl = window.URL.createObjectURL(file);
          insertAfterFocus(block, index++);
        };
        processFilesList.push(addBlock());
        changed = true;
      } else if (file.type.startsWith("video/")) {
        if (file.size > 200 * 1024 * 1024) {
          window.showError(
            __("Error"),
            __("Max limit size for videos") +
              " 200mb. " +
              __("Please reduce image size and try again."),
          );
          continue;
        }
        if (user?.karma < 4 || user?.userpoints < 5) {
          logT(
            "editor",
            "skip video because low karma",
            user?.karma,
            "userpoints",
            user?.userpoints,
          );
          continue;
        }
        const addBlock = async () => {
          const block = { type: "video" };
          const randomFileName =
            "file:" +
            (Math.random() + 1).toString(36).substring(7) +
            (Math.random() + 1).toString(36).substring(7);
          if (!temporary) {
            await setStorageValue(randomFileName, await videoData(file), true);
            block.storageFile = randomFileName;
          }
          block.videoUrl = window.URL.createObjectURL(file);
          insertAfterFocus(block, index++);
        };
        processFilesList.push(addBlock());
        changed = true;
      }
    }
    if (changed) {
      Promise.all(processFilesList).then(() => {
        logT("editor", "processing files finished");
        setData(Object.assign({}, data));
      });
    }
    return changed;
  };

  return (
    <ul
      className="blocks-editor"
      onDrop={(event) => {
        const files = event.dataTransfer.files;
        event.preventDefault();
        let changed = processFiles(files);
        if (changed) {
          logT("editor", "drop files", files);
        }
      }}
      onPaste={(event) => {
        const clipboardData = event.clipboardData || window.clipboardData;

        if (clipboardData.types.includes("Files")) {
          const files = clipboardData.files;

          // Iterate through the files to find images and process them
          let changed = processFiles(files);
          if (changed) {
            logT("editor", "paste files", files);
            event.preventDefault();
          }
        }
      }}
    >
      {data?.blocks
        ? data.blocks.map((block, i) => (
            <li key={i} className="block">
              <div
                className="block-row"
                onFocus={() => {
                  currentFocusedBlock.current = i;
                }}
                onBlur={() => {
                  currentFocusedBlock.current = -1;
                }}
              >
                <div className="block-content">
                  {block.type == "text" && (
                    <Text
                      key={i}
                      block={block}
                      minimal={minimal}
                      minimalCollapsed={minimalCollapsed}
                      editorSource={editorSource}
                      textHintMinimal={textHintMinimal}
                      onPreChange={(text) => {
                        let copyData = Object.assign({}, data);
                        copyData.blocks = Array.from(data.blocks);
                        copyData.blocks[i] = Object.assign({}, data.blocks[i]);
                        copyData.blocks[i].data = text;
                        if (onPreData) onPreData(copyData);
                      }}
                      onChange={(text) => {
                        logT("editor", "changed text trigger");
                        block.data = text;
                        setData(Object.assign({}, data));
                      }}
                      onPaste={(event) => {
                        const clipboardData =
                          event.clipboardData || window.clipboardData;

                        if (
                          clipboardData.types.includes("text/plain") ||
                          clipboardData.types.includes("text/html")
                        ) {
                          const text = clipboardData.getData("Text");
                          const link = text;

                          let insertBlocks = 0;
                          const MAX_BLOCKS = 3;

                          const twitchRegex =
                            /(?:twitch\.tv)\/(?:videos\/([0-9]+)|([0-9A-Za-z]+))/g;
                          let twitchMatch;
                          while (
                            (twitchMatch = twitchRegex.exec(link)) != null &&
                            insertBlocks < MAX_BLOCKS
                          ) {
                            if (
                              data.blocks.findIndex(
                                (block) =>
                                  block.video == twitchMatch[1] &&
                                  block.channel == twitchMatch[2],
                              ) < 0
                            ) {
                              insertBlocks++;
                              logT(
                                "paste",
                                "found twitch block in text",
                                twitchMatch[1] || twitchMatch[2],
                                "count",
                                insertBlocks,
                              );
                              insertAfterFocus({
                                type: "twitch",
                                data: {
                                  video: twitchMatch[1],
                                  channel: twitchMatch[2],
                                },
                              });
                            }
                          }
                          const youtubeRegex =
                            /(?:youtu.be\/|v\/|u\/\w\/|embed\/|(?<!rutube\.ru\/)shorts\/|live\/|watch\?v=)([^#\&\?\s]*)/g;
                          let youtubeMatch;
                          while (
                            (youtubeMatch = youtubeRegex.exec(link)) != null &&
                            insertBlocks < MAX_BLOCKS
                          ) {
                            if (
                              data.blocks.findIndex(
                                (block) => block.data == youtubeMatch[1],
                              ) < 0
                            ) {
                              insertBlocks++;
                              logT(
                                "paste",
                                "found youtube block in text",
                                youtubeMatch[1],
                                "count",
                                insertBlocks,
                              );
                              insertAfterFocus({
                                type: "youtube",
                                data: youtubeMatch[1],
                              });
                            }
                          }
                          const vkRegex =
                            /(?:vk\.com).*video(\-?[0-9]+)_([0-9]+)/g;
                          let vkMatch;
                          while (
                            (vkMatch = vkRegex.exec(link)) != null &&
                            insertBlocks < MAX_BLOCKS
                          ) {
                            if (
                              data.blocks.findIndex(
                                (block) =>
                                  block.oid == vkMatch[1] &&
                                  block.id == vkMatch[2],
                              ) < 0
                            ) {
                              insertBlocks++;
                              logT(
                                "paste",
                                "found vk block in text",
                                vkMatch[2],
                                "count",
                                insertBlocks,
                              );
                              insertAfterFocus({
                                type: "vk",
                                data: { oid: vkMatch[1], id: vkMatch[2] },
                              });
                            }
                          }
                          const coubRegex =
                            /.*(?:coub\.com)\/view\/([0-9A-Za-z]+)/g;
                          let coubMatch;
                          while (
                            (coubMatch = coubRegex.exec(link)) != null &&
                            insertBlocks < MAX_BLOCKS
                          ) {
                            if (
                              data.blocks.findIndex(
                                (block) => block.data == coubMatch[1],
                              ) < 0
                            ) {
                              insertBlocks++;
                              logT(
                                "paste",
                                "found coub block in text",
                                coubMatch[1],
                                "count",
                                insertBlocks,
                              );
                              insertAfterFocus({
                                type: "coub",
                                data: coubMatch[1],
                              });
                            }
                          }
                          const rutubeRegex =
                            /.*(?:rutube\.ru)\/(?:video|shorts)\/([0-9A-Za-z]+)/g;
                          let rutubeMatch;
                          while (
                            (rutubeMatch = rutubeRegex.exec(link)) != null &&
                            insertBlocks < MAX_BLOCKS
                          ) {
                            if (
                              data.blocks.findIndex(
                                (block) => block.data == rutubeMatch[1],
                              ) < 0
                            ) {
                              insertBlocks++;
                              logT(
                                "paste",
                                "found rutube block in text",
                                rutubeMatch[1],
                                "count",
                                insertBlocks,
                              );
                              insertAfterFocus({
                                type: "rutube",
                                data: rutubeMatch[1],
                              });
                            }
                          }
                          if (insertBlocks > 0) {
                            setData(Object.assign({}, data));
                          }
                        }
                      }}
                    />
                  )}
                  {block.type == "image" && (
                    <Image
                      key={i}
                      block={block}
                      userid={data.userid}
                      onChange={async (images) => {
                        if (
                          images?.length > 1 &&
                          user?.settings?.editorFilesSortStable
                        ) {
                          images = Array.from(images).sort((a, b) => {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                          });
                          logT(
                            "editor",
                            "sorted files",
                            images.length,
                            "from A to Z",
                          );
                        }
                        for (
                          let index = 0;
                          index < images?.length || 0;
                          index++
                        ) {
                          const image = images[index];
                          if (image) {
                            if (index == 0) {
                              delete block.file;
                              const randomFileName =
                                "file:" +
                                (Math.random() + 1).toString(36).substring(7) +
                                (Math.random() + 1).toString(36).substring(7);
                              if (!temporary) {
                                if (block.storageFile) {
                                  clearStorageValue(block.storageFile);
                                }
                                await setStorageValue(
                                  randomFileName,
                                  await imageData(image),
                                  true,
                                );
                                block.storageFile = randomFileName;
                              }
                              logT("image", "replace image at index", i);
                              block.imageUrl =
                                window.URL.createObjectURL(image);
                            } else {
                              const block = { type: "image" };
                              const randomFileName =
                                "file:" +
                                (Math.random() + 1).toString(36).substring(7) +
                                (Math.random() + 1).toString(36).substring(7);
                              if (!temporary) {
                                await setStorageValue(
                                  randomFileName,
                                  await imageData(image),
                                  true,
                                );
                                block.storageFile = randomFileName;
                              }
                              logT("image", "add image at index", i + index);
                              block.imageUrl =
                                window.URL.createObjectURL(image);
                              data.blocks.splice(i + index, 0, block);
                            }
                          }
                        }
                        setData(Object.assign({}, data));
                      }}
                    />
                  )}
                  {block.type == "youtube" && (
                    <div className="youtube-container">
                      <Video
                        key={i}
                        type="youtube"
                        block={block}
                        onChange={(link, type) => {
                          block.data = link;
                          block.type = type;
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                  {block.type == "vk" && (
                    <div className="vk-container">
                      <Video
                        key={i}
                        type="vk"
                        block={block}
                        onChange={(link, type) => {
                          block.data = link;
                          block.type = type;
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                  {block.type == "twitch" && (
                    <div className="twitch-container">
                      <Video
                        key={i}
                        type="twitch"
                        block={block}
                        onChange={(link, type) => {
                          block.data = link;
                          block.type = type;
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                  {block.type == "coub" && (
                    <div className="coub-container">
                      <Video
                        key={i}
                        type="coub"
                        block={block}
                        onChange={(link, type) => {
                          block.data = link;
                          block.type = type;
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                  {block.type == "rutube" && (
                    <div className="rutube-container">
                      <Video
                        key={i}
                        type="rutube"
                        block={block}
                        onChange={(link, type) => {
                          block.data = link;
                          block.type = type;
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                  {(block.type == "video" || block.type == "animation") && (
                    <div className="video-container">
                      <Video
                        key={i}
                        type={block.type}
                        userid={data.userid}
                        block={block}
                        onChange={async (video, type) => {
                          delete block.file;
                          if (type == "video") {
                            const randomFileName =
                              "file:" +
                              (Math.random() + 1).toString(36).substring(7) +
                              (Math.random() + 1).toString(36).substring(7);
                            if (!temporary) {
                              if (block.storageFile) {
                                clearStorageValue(block.storageFile);
                              }
                              await setStorageValue(
                                randomFileName,
                                await videoData(video),
                                true,
                              );
                              block.storageFile = randomFileName;
                            }
                            block.videoUrl = window.URL.createObjectURL(video);
                            block.type = type;
                          } else {
                            block.data = video;
                            block.type = type;
                          }
                          setData(Object.assign({}, data));
                        }}
                      />
                    </div>
                  )}
                </div>
                {!minimal || !minimalCollapsed ? (
                  <div className="block-order">
                    {i != 0 ? (
                      <div
                        className="order-button"
                        tabIndex="0"
                        onClick={() => changeIndex(i, -1)}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </div>
                    ) : null}
                    <div
                      className="remove-button"
                      tabIndex="0"
                      onClick={() => removeBlock(i)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                    {i < data.blocks.length - 1 ? (
                      <div
                        className="order-button"
                        tabIndex="0"
                        onClick={() => changeIndex(i, 1)}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {block.displaySettings && (!minimal || !minimalCollapsed) ? (
                <div className="settings">
                  <Checkbox
                    value={!!block.hideBlockFromSocialNetworks}
                    onSwitch={(checked) => {
                      block.hideBlockFromSocialNetworks = checked;
                      setData(Object.assign({}, data));
                    }}
                    text={__("Hide this block from social networks")}
                  />
                  <Checkbox
                    value={!!block.spoiler}
                    onSwitch={(checked) => {
                      block.spoiler = checked;
                      setData(Object.assign({}, data));
                    }}
                    text={__("Block spoiler")}
                  />
                  <Checkbox
                    value={!!block.adult}
                    onSwitch={(checked) => {
                      block.adult = checked;
                      setData(Object.assign({}, data));
                    }}
                    text={__("Adult block")}
                  />
                  {block.type == "video" || block.type == "animation" ? (
                    <Checkbox
                      value={block.animation}
                      onSwitch={(checked) => {
                        block.animation = checked;
                        setData(Object.assign({}, data));
                      }}
                      text={__("Convert video block to gif / animation")}
                    />
                  ) : null}
                </div>
              ) : null}
              {!minimal || !minimalCollapsed ? (
                <div className="block-controls">
                  <div
                    className="add-block-text-button"
                    tabIndex="0"
                    onClick={() => addBlock(i, "text")}
                  >
                    +
                    <svg viewBox="0 0 50 50">
                      <path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z" />
                    </svg>
                  </div>
                  <div
                    className="add-block-image-button"
                    tabIndex="0"
                    onClick={() => addBlock(i, "image")}
                  >
                    +<FontAwesomeIcon icon={faImage} />
                  </div>
                  <div
                    className="add-block-video-button"
                    tabIndex="0"
                    onClick={() => addBlock(i, "video")}
                  >
                    +<FontAwesomeIcon icon={faVideo} />
                  </div>
                  <div
                    className={
                      "settings-button" +
                      (block.displaySettings ? " active" : "")
                    }
                    tabIndex="0"
                    onClick={() => {
                      if (typeof block.displaySettings == "undefined")
                        block.displaySettings = true;
                      else block.displaySettings = !block.displaySettings;
                      setData(Object.assign({}, data));
                    }}
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </div>
                </div>
              ) : null}
            </li>
          ))
        : null}
    </ul>
  );
};

export { dataParse };
