import React, { useState, useEffect, useRef } from "react";
import RecentPosts from "./RecentPosts";

const Answers = () => {
  useEffect(() => {
    document.title = `${__("Answers")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="answers"
          onlyAnswers={true}
          recentPopularSwitcher={true}
          noLimitation={true}
          leftRatingDisplay={true}
          displayOnlyComments={true}
          showReply={true}
          showTreeReply={true}
          tree={true}
          treeMaxLevel={1}
        />
      </div>
    </div>
  );
};
export default Answers;
