import React, { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import {
  dateObjectToDate,
  dateToDateObject,
  getCookie,
  timeAgo,
  transliterateLink,
} from "../global/Global";
import Article, { articleObject } from "./Article";
import { bbParse, limitBBcode } from "../global/BBParser";
import {
  htmlEscape,
  removeMagnetLinks,
  links2html,
  mail2html,
} from "../global/Global";
import ArticleEditor, { dataParse } from "./ArticleEditor";
import DefaultAvatar_150 from "../default-avatar_150.png";
import Checkbox from "./Checkbox";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import Tooltip from "./Tooltip";
import Menu from "./Menu";
import Input from "./Input";
import { DtPicker } from "react-calendar-datetime-picker";
import _ from "lodash";
import LinearProgress from "./LinearProgress";
import {
  faBell,
  faComment,
  faEye,
  faHeart,
  faNewspaper,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faEllipsis,
  faGlobe,
  faHeartCrack,
  faList,
  faLock,
  faMars,
  faReplyAll,
  faUserMinus,
  faUserPlus,
  faVenus,
  faXmark,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTelegram } from "@fortawesome/free-brands-svg-icons";
import MouseFocusContainer from "./MouseFocusContainer";
import ReplyForm from "./ReplyForm";
import { v4 as uuidv4 } from "uuid";

const parsePost = (text) => {
  text = removeMagnetLinks(text);
  text = htmlEscape(text);

  text = bbParse(text);

  // FIXME: too much performace usage for mail
  // text = mail2html(text);
  text = links2html(text);

  text = text.replaceAll("\n", "<br />");
  return text;
};

const UserSocials = ({ user, myUser }) => {
  if (
    typeof myUser?.settings?.showUserLinks != "undefined" &&
    !myUser?.settings?.showUserLinks
  ) {
    return;
  }

  if (!user.telegram && !user.discord && !user.homepage) {
    return;
  }

  return (
    <ul className="userSocials">
      {user.telegram?.length > 0 && (
        <li className="telegram">
          <FontAwesomeIcon icon={faTelegram} />
          <Link to={`https://t.me/${user.telegram}`}>{user.telegram}</Link>
        </li>
      )}
      {user.discord?.length > 0 && (
        <li className="discord">
          <FontAwesomeIcon icon={faDiscord} />
          <Link to={`https://discord.com`}>{user.discord}</Link>
        </li>
      )}
      {user.homepage?.length > 0 && (
        <li className="homepage">
          <FontAwesomeIcon icon={faGlobe} />
          <Link to={user.homepage}>{__("homepage")}</Link>
        </li>
      )}
    </ul>
  );
};

const Post = ({
  post,
  user,
  forum,
  fetchPosts,
  minimal,
  maxTextSize,
  onReplyClick,

  showReply = false,
  forceTitle = false,
  leftRatingDisplay = false,
  collapsable = false,
  onCollapsed = null,
  onUncollapse = null,
  uncollapse = false,
  onTextFullQuote = null,
  textFullQuote = null,
  noFirstImageAdult = false,
  onFirstImage = null,
  onFirstYoutubeBlock = null,
  onFirstTextBlock = null,
  onFirstVideoPreviewImage = null,
  sticky = false,
  postLink = false,
  enableDataPostid = false,
  hidden = false,
  setHidden = null,
  source = "",
  onNextPostScroll = null,
  onRenderFinish = null,
  preview = false,

  tree = false,
  treeMaxLevel = undefined,
  treeRoot = true,

  showTreeReply = false,
  replyAfterPostid = 0,
  replyAfterPostidUniqueId = null,
  replyPostEnabled = false,
  replyMinimal = false,
  replyMinimalTextHint = null,
  setReplyPostEnabled = null,
  onReply = null,
  showReplyForm = false,
}) => {
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pageObj, setPageObj] = useState(articleObject(post.pagetext));
  const [originalContent, setOriginalContent] = useState(post.original);
  const [adult, setAdult] = useState(post.adult);
  const [threadTitle, setThreadTitle] = useState(post.threadtitle || "");
  const [forums, setForums] = useState([]);
  const [showAdultPost, setShowAdultPost] = useState(false);

  const tagsInput = useRef();
  const leftFollower = useRef();
  const [tags, setTags] = useState(post.taglist || "");
  const [searchTagList, setSearchTagList] = useState([]);

  const [selectionForum, setSelectionForum] = useState(forum);
  const [selectionForumTitle, setSelectionForumTitle] = useState();
  const [searchForumList, setSearchForumList] = useState([]);
  const [schedulePublication, setScedulePublication] = useState(
    post.dateline * 1000 > Date.now(),
  );
  const [scheduleTime, setSceduleTime] = useState(
    post.dateline * 1000 > Date.now()
      ? new Date(post.dateline * 1000)
      : new Date(),
  );
  const [sendProgress, setSendProgress] = useState(0);
  const scheduleTimeProtector = useRef();
  const postTextRef = useRef();

  const ignoreTagList = [];
  let searchTagTimeout = useRef(null);
  let tabTagTimeout = useRef(null);
  let tabTagIndex = useRef(0);
  let tabTagPattern = useRef(null);
  let searchForumTimeout;

  const [connectedPosts, setConnectedPosts] = useState(
    post.connectedPosts || [{ threadid: -1 }],
  );
  const [connectedPostLink, setConnectedPostLink] = useState("");
  const [connectedPostSearchResults, setConnectedPostSearchResults] = useState(
    [],
  );
  let searchConnectedPostTimeout = useRef(null);

  const postUniqueId = useRef(uuidv4());

  useDidUpdateEffect(() => {
    if (forum?.forumid != selectionForum?.forumid) {
      logT("update", "updated post forum", forum);
      setSelectionForum(forum);
    }
  }, [forum]);

  const getConnectedPosts = async () => {
    if (post.parentid != 0) {
      return;
    }
    logT("post", "get connected posts", post.threadid);
    const data = await window.TALKVIOAPI("posts", {
      threadid: post.threadid,
      similar: true,
      similarManual: true,
    });
    logT("post", "get connected posts", post.threadid, "result", data.posts);
    if (data.posts?.length > 0) {
      setConnectedPosts(
        data.posts.map((post) => ({
          postid: post.postid,
          threadid: post.threadid,
          title: post.threadtitle,
        })),
      );
    }
  };

  useEffect(() => {
    if (edit) {
      getConnectedPosts();
    }
  }, [edit]);

  useDidUpdateEffect(() => {
    if (post.taglist && post.taglist != tags) {
      logT("update", "updated post tags", post.taglist);
      setTags(post.taglist);
    }

    const newArticle = articleObject(post.pagetext);
    if (edit) {
      const videoUpdateBlocks = pageObj.blocks?.filter(
        (block) => block.type == "video" && block.processing,
      );
      if (videoUpdateBlocks.length > 0) {
        logT("article", "merge video updates", videoUpdateBlocks);
        const newContents = {};
        newArticle?.blocks
          ?.filter((block) => block.type == "video")
          .forEach((block) => (newContents[block.file] = block));
        videoUpdateBlocks.forEach((block) => {
          if (newContents[block.file]) {
            block.processing = newContents[block.file].processing;
          }
        });
        setPageObj(Object.assign({}, pageObj));
      }
      logT("article", "current article in editing, ignore new article content");
      return;
    }
    if (_.isEqual(newArticle, pageObj)) {
      return;
    }
    logT("article", "update article", newArticle);
    setPageObj(newArticle);
  }, [post]);

  useEffect(() => {
    if (leftRatingDisplay) {
      if (leftFollower?.current) {
        const rect = leftFollower.current.getBoundingClientRect();
        window.headerUnblockedAreaStart = rect.left - 85;
        window.headerUnblockedAreaEnd = rect.right + 85;
      }
    } else {
      window.headerUnblockedAreaStart = null;
      window.headerUnblockedAreaEnd = null;
    }
    return () => {
      window.headerUnblockedAreaStart = null;
      window.headerUnblockedAreaEnd = null;
    };
  }, [leftRatingDisplay]);

  useEffect(() => {
    if (!user?.settings?.viewWhenLongLook) {
      return;
    }

    if (!window.IntersectionObserver) {
      return;
    }

    if (sticky) {
      return;
    }

    if (!postTextRef?.current) {
      return;
    }

    if (post.parentid != 0) {
      return;
    }

    if (source == "posts") {
      return;
    }

    if (hidden && !user?.settings?.viewWhenShortLook) {
      return;
    }

    let longViewTimeout = null;
    let shortViewTimeout = null;
    let shortViewListLocal = {};
    if (!window.shortReadList) {
      window.shortReadList = {};
    }

    const aproxTextSize =
      pageObj?.blocks
        ?.filter((block) => block.type == "text")
        .reduce((accumulator, block) => accumulator + block.data.length, 0) ||
      0;
    let readTime = aproxTextSize > 0 ? aproxTextSize / 22 : 0;
    if (readTime > 70) {
      readTime = 70;
    }
    if (readTime < 8) {
      readTime = 8;
    }
    readTime = readTime | 0;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logT(
              "post",
              "enter post read",
              post.postid,
              "text size",
              aproxTextSize,
              "calc time",
              aproxTextSize,
              "read time",
              readTime,
              "short read",
              user?.settings?.viewWhenShortLook,
            );
            if (
              user?.settings?.viewWhenShortLook &&
              user.settings?.hideViewHistory
            ) {
              if (shortViewTimeout) {
                clearTimeout(shortViewTimeout);
              }
              shortViewListLocal[post.postid] = true;
              shortViewTimeout = setTimeout(() => {
                logT(
                  "post",
                  "mark post read as long short looking at it",
                  post.postid,
                );
                window.shortReadList[post.postid] = true;
                const token = getCookie("token") || "";
                const userid = parseInt(getCookie("userid")) || 0;
                window.TALKVIOAPI("view", {
                  threadid: parseInt(post.threadid) || 0,
                  postid: parseInt(post.postid) || 0,
                  short: true,
                  userid,
                  token,
                });
              }, 4 * 1000);
            } else {
              if (longViewTimeout) {
                clearTimeout(longViewTimeout);
              }
              longViewTimeout = setTimeout(() => {
                logT(
                  "post",
                  "mark post read as long looking at it",
                  post.postid,
                );
                const token = getCookie("token") || "";
                const userid = parseInt(getCookie("userid")) || 0;
                window.TALKVIOAPI("view", {
                  threadid: parseInt(post.threadid) || 0,
                  postid: parseInt(post.postid) || 0,
                  userid,
                  token,
                });
              }, readTime * 1000);
            }
          } else {
            logT("post", "out post read", post.postid);
            if (longViewTimeout) {
              clearTimeout(longViewTimeout);
              longViewTimeout = null;
            }
            if (shortViewTimeout) {
              clearTimeout(shortViewTimeout);
              shortViewTimeout = null;
            }
            if (
              user?.settings?.viewWhenShortLook &&
              user.settings?.hideViewHistory &&
              !window.shortReadList[post.postid] &&
              shortViewListLocal[post.postid]
            ) {
              logT("post", "mark post read as out of short view", post.postid);
              window.shortReadList[post.postid] = true;
              const token = getCookie("token") || "";
              const userid = parseInt(getCookie("userid")) || 0;
              window.TALKVIOAPI("view", {
                threadid: parseInt(post.threadid) || 0,
                postid: parseInt(post.postid) || 0,
                short: true,
                userid,
                token,
              });
            }
          }
        });
      },
      { threshold: [0.25] },
    );

    observer.observe(postTextRef.current);
    logT("post", "listen long view post", post.postid);

    return () => {
      logT("post", "stop listen long view post", post.postid);
      observer.disconnect();
    };
  }, [user?.settings?.viewWhenLongLook, pageObj, hidden]);

  const fetchForums = async () => {
    if (forums.length > 0) {
      return; // doesn't need
    }
    const data = await window.TALKVIOAPI("forums", {});
    setForums(data.forums);
  };

  const searchTag = async (tagSeach) => {
    if (!tagSeach) {
      setSearchTagList([]);
      return;
    }

    if (!tagSeach.trim()) {
      setSearchTagList([]);
      return;
    }

    const data = await window.TALKVIOAPI("tag", {
      search: tagSeach,
    });
    logT("tags", "search result", tagSeach, "result", data?.tags);
    if (data?.tags) {
      setSearchTagList(
        data.tags.map((tag) => Object.assign(tag, { pattern: tagSeach })),
      );
    }
  };

  const searchForum = async (forumSearch) => {
    if (!forumSearch || forumSearch.length == 0) {
      setSearchForumList([]);
      return;
    }

    const data = await window.TALKVIOAPI("forums", {
      search: forumSearch,
    });
    if (data.forums?.length == 1) {
      setSearchForumList([]);
      setSelectionForum(data.forums[0]);
      setSelectionForumTitle(data.forums[0].title);
    } else if (data.forums?.length > 1) {
      setSearchForumList(data.forums);
    } else {
      setSearchForumList([]);
    }
  };

  useDidUpdateEffect(() => {
    if (edit) {
      logT("editor", "enable postedit key blocking");
      window.blockKeyTag("posts", "postedit");
    } else {
      logT("editor", "disable postedit key blocking");
      window.unblockKeyTag("posts", "postedit");
    }
  }, [edit]);

  useEffect(() => {
    if (!edit) return;

    fetchForums();
  }, [edit]);

  const extractConnectedPost = async (link) => {
    const url = new URL(link);
    const pathParts = url.pathname.split("/");

    // Handle direct post links
    let data;
    if (pathParts[1] === "post") {
      const postId = parseInt(pathParts[2]);
      data = await window.TALKVIOAPI("posts", {
        postid: postId,
        userid: parseInt(getCookie("userid")),
        token: getCookie("token"),
      });
    } else if (pathParts[1] === "threads") {
      const threadIdPart = pathParts[2];
      const threadId = parseInt(threadIdPart.split("-")[0]);
      data = await window.TALKVIOAPI("posts", {
        threadid: threadId,
        onlyPosts: true,
        userid: parseInt(getCookie("userid")),
        token: getCookie("token"),
      });
    }

    if (data?.posts?.[0]?.userid !== user.userid && !user.isModerator) {
      window.showError(
        __("Error"),
        __("You cannot connect posts from other users"),
      );
      return null;
    }

    if (data?.posts?.length > 0) {
      return {
        postid: data.posts[0].postid,
        threadid: data.posts[0].threadid,
        title: data.posts[0].threadtitle,
      };
    }
    return null;
  };

  const addConnectedPost = async (remoteLink = null) => {
    const link = remoteLink || connectedPostLink;
    logT("connectedPost", "add", link);
    if (link) {
      const post = await extractConnectedPost(link);
      if (post && !connectedPosts.find((p) => p.threadid === post.threadid)) {
        setConnectedPosts([
          ...(connectedPosts?.[0]?.threadid !== -1 ? connectedPosts : []),
          post,
        ]);
        setConnectedPostLink("");
        logT("connectedPost", "added", post);
      } else {
        setConnectedPostLink("");
      }
    }
  };

  const ConnectedPost = ({ post, onRemove }) => {
    return (
      <div className="connectedPost">
        <div className="title">{post.title}</div>
        <div className="removeButton" onClick={onRemove}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
    );
  };

  const saveEdits = async () => {
    logT("post", "saving edits");
    const token = getCookie("token");
    const userid = parseInt(getCookie("userid"));
    setEdit(false);
    setSaving(true);
    const newText =
      (pageObj && (await dataParse(pageObj, true))) || post.pagetext;
    const data = await window.TALKVIOAPI(
      "editPost",
      {
        userid,
        token,
        postid: post.postid,
        text: newText,
        original: !!originalContent,
        adult: !!adult,
        title: threadTitle || "",
        forumid: parseInt(selectionForum?.forumid) || 0,
        tags: tags || "",
        scheduleDate:
          schedulePublication && scheduleTime
            ? (scheduleTime.getTime() / 1000) | 0
            : undefined,
        connections:
          connectedPosts?.length > 0 && connectedPosts[0].threadid != -1
            ? connectedPosts?.map((post) => post.threadid)
            : connectedPosts?.length == 0
              ? []
              : undefined,
      },
      { chunkMode: true, progress: (progress) => setSendProgress(progress) },
    );
    window.displayError(data);
    setSaving(false);
    setSendProgress(0);
    logT("post", "edits saved");
  };

  const BottomControls = () => {
    return (
      <>
        {true ? (
          <div className="downcontrols">
            <div className="leftstats">
              {true ? (
                <div
                  className={
                    "control" +
                    (leftRatingDisplay ? " like-dislike-disable" : "")
                  }
                >
                  <Tooltip hint={__("Like this post, increate it rating")}>
                    <div
                      className={
                        "like rating" +
                        (post.setrating == "+" ? " pos" : "") +
                        (post.setrating == "-" ? " neg" : "")
                      }
                      onClick={async () => {
                        const token = getCookie("token");
                        const userid = parseInt(getCookie("userid"));
                        if (token && userid) {
                          const data = await window.TALKVIOAPI(
                            "changePostRating",
                            {
                              userid,
                              token,
                              postid: post.postid,
                              reduce: false,
                            },
                          );
                          window.displayError(data);
                        } else {
                          if (window.needRegistationAction)
                            window.needRegistationAction();
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faHeart} />
                      <span>{post.postrating}</span>
                    </div>
                  </Tooltip>
                  {user && user.userid > 0 ? (
                    <Tooltip hint={__("Dislike the post, decreate it rating")}>
                      <div
                        className="dislike rating"
                        onClick={async () => {
                          const token = getCookie("token");
                          const userid = parseInt(getCookie("userid"));
                          if (token && userid) {
                            const data = await window.TALKVIOAPI(
                              "changePostRating",
                              {
                                userid,
                                token,
                                postid: post.postid,
                                reduce: true,
                              },
                            );
                            window.displayError(data);
                          } else {
                            if (window.needRegistationAction)
                              window.needRegistationAction();
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faHeartCrack} />
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
              {post.parentid == 0 ? (
                <Tooltip
                  hint={__(
                    "Number of comments in this post, click to go to the thread",
                  )}
                >
                  <div className="control replies">
                    <Link
                      to={
                        "/threads/" +
                        post.threadid +
                        `-${transliterateLink(post.threadtitle)}`
                      }
                      state={{
                        toComments: true,
                      }}
                    >
                      <FontAwesomeIcon icon={faComment} />
                      <span>{post.replycount}</span>
                      {post.newcomments && post.newcomments > 0 ? (
                        <sup className="newcomments">+{post.newcomments}</sup>
                      ) : null}
                    </Link>
                  </div>
                </Tooltip>
              ) : null}
              {post.parentid == 0 ? (
                <Tooltip
                  hint={__(
                    "Number of views in this post, click to go to the thread",
                  )}
                >
                  <div className="control views">
                    <Link
                      to={
                        "/threads/" +
                        post.threadid +
                        `-${transliterateLink(post.threadtitle)}`
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                      <span>{post.views}</span>
                    </Link>
                  </div>
                </Tooltip>
              ) : null}
              {user?.userid > 0 && user.userid != post.postid ? (
                <Tooltip hint={__("Follow/subscribe to the post author")}>
                  <div
                    className={
                      "control more-hide postSubscribe" +
                      (post.subscriptionid > 0 ? " unsubscribe" : " subscribe")
                    }
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      const token = getCookie("token");
                      const userid = parseInt(getCookie("userid"));
                      const data = await window.TALKVIOAPI("subscribe", {
                        userid,
                        token,
                        targetuserid: post.userid,
                      });
                      window.displayError(data);
                    }}
                  >
                    {post.subscriptionid > 0 ? (
                      <FontAwesomeIcon icon={faUserMinus} />
                    ) : (
                      <FontAwesomeIcon icon={faUserPlus} />
                    )}
                  </div>
                </Tooltip>
              ) : null}
              {user?.userid > 0 && post.parentid == 0 ? (
                <Tooltip
                  hint={__(
                    "Subscribe to this thread, you will receive the notification when someone add the comment to this thread",
                  )}
                >
                  <div
                    className={
                      "control more-hide threadSubscribe" +
                      (post.subscribethreadid > 0
                        ? " unsubscribe"
                        : " subscribe")
                    }
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      const token = getCookie("token");
                      const userid = parseInt(getCookie("userid"));
                      const data = await window.TALKVIOAPI("subscribeThread", {
                        userid,
                        token,
                        threadid: post.threadid,
                      });
                      window.displayError(data);
                    }}
                  >
                    <FontAwesomeIcon icon={faBell} />
                  </div>
                </Tooltip>
              ) : null}
              {user?.userid > 0 ? (
                <Tooltip
                  hint={__(
                    "Add this post or comment to the bookmarks/favorites, you can read or back to it later in your user right panel > Bookmarks",
                  )}
                >
                  <div
                    className={
                      "control more-hide bookmarkPost" +
                      (post.bookmarkid > 0 ? " unbookmark" : " bookmark")
                    }
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      const token = getCookie("token");
                      const userid = parseInt(getCookie("userid"));
                      const data = await window.TALKVIOAPI("bookmark", {
                        userid,
                        token,
                        postid: post.postid,
                      });
                      window.displayError(data);
                    }}
                  >
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </Tooltip>
              ) : null}
              {post.editorpost &&
              (post.userid == user?.userid || user?.isOwner) ? (
                <Tooltip hint={__("This post calculated as editor post")}>
                  <div className="control editorPost more-hide">
                    <FontAwesomeIcon icon={faNewspaper} />
                  </div>
                </Tooltip>
              ) : null}
              {
                <div className="control more">
                  <RightPostMenu />
                </div>
              }
            </div>
            <div className="controls">
              {user?.userid && showReply && !edit && (
                <div
                  className="clickButton"
                  onClick={async () => {
                    if (onReplyClick)
                      onReplyClick(post.postid, postUniqueId.current);
                  }}
                >
                  <span className="answer-text">{__("Reply")}</span>
                  <FontAwesomeIcon className="answer-icon" icon={faComment} />
                </div>
              )}
              {user &&
              (user.isAdmin ||
                user.isModerator ||
                user.userid == post.userid) ? (
                <>
                  {edit ? (
                    <>
                      <div
                        className="clickButton"
                        onMouseUp={async () => {
                          setPageObj(articleObject(post.pagetext));
                          setOriginalContent(post.original);
                          setAdult(post.adult);
                          setThreadTitle(post.threadtitle);
                          setTags(post.taglist);
                          setEdit(false);
                        }}
                      >
                        {__("Cancel")}
                      </div>
                      <div className="clickButton" onMouseUp={saveEdits}>
                        {__("Save")}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <></>
              )}
              {user && saving ? (
                <div>
                  {__("Post saving now, please wait")}...{" "}
                  {sendProgress > 0
                    ? sendProgress == 1
                      ? __("processing")
                      : `${(sendProgress * 100) | 0}%`
                    : __("preparing")}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const RightPostMenu = () => (
    <Menu
      items={[
        {
          name: __("copy post link"),
          click: async (close) => {
            navigator.clipboard
              .writeText(`https://talkvio.com/post/${post.postid}`)
              .then(
                () => {
                  close();
                },
                () => {
                  close();
                },
              );
          },
        },
        {
          name: __("share link"),
          items: [
            typeof window.TalkvioAndroid != "undefined"
              ? {
                  name: __("share using") + " android",
                  click: async (close) => {
                    logT("share", "share using android");
                    if (typeof window.TalkvioAndroid != "undefined") {
                      window.TalkvioAndroid.shareLink(
                        post.parentid == 0
                          ? `https://talkvio.com/threads/${post.threadid}-${transliterateLink(post.threadtitle)}`
                          : `https://talkvio.com/post/${post.postid}`,
                      );
                    }
                    close();
                  },
                }
              : null,
            {
              name: __("share using") + " telegram",
              click: async (close) => {
                logT("share", "share using telegram");
                window
                  .open(
                    "https://t.me/share/url?url=" +
                      encodeURI(
                        post.parentid == 0
                          ? `https://talkvio.com/threads/${post.threadid}-${transliterateLink(post.threadtitle)}&external_talkvio=1`
                          : `https://talkvio.com/post/${post.postid}&external_talkvio=1`,
                      ),
                    "_blank",
                  )
                  .focus();
                close();
              },
            },
          ],
        },
        (user?.isAdmin ||
          (user?.userid == post.userid && post.parentid == 0)) && {
          name: __("raise this post"),
          click: async (close) => {
            const token = getCookie("token");
            const userid = parseInt(getCookie("userid"));
            const data = await window.TALKVIOAPI("raisePost", {
              userid,
              token,
              postid: post.postid,
            });
            window.displayError(data);
            close();
          },
        },
        user &&
          (user.isAdmin || user.isModerator || user.userid == post.userid) &&
          !edit &&
          !saving && {
            name: __("edit"),
            click: (close) => {
              close();
              setEdit(true);
            },
          },
        user?.userid &&
          ((post.userid == user.userid &&
            (post.originaldate >= Date.now() / 1000 - 1 * 60 * 60 ||
              !post.visible) &&
            (post.parentid != 0 || post.replycount == 0)) ||
            user.isAdmin ||
            user.isModerator) && {
            name: __("remove"),
            click: async (close) => {
              close();
              window.confirmMessage(
                __("Are you sure that you wanna delete this post?"),
                async () => {
                  const token = getCookie("token");
                  const userid = parseInt(getCookie("userid"));
                  const data = await window.TALKVIOAPI("deletePost", {
                    userid,
                    token,
                    postid: post.postid,
                  });
                  window.displayError(data);
                  close();
                  fetchPosts(true);
                },
              );
            },
          },
        user?.userid > 0 &&
          user.userid != post.postid && {
            name: (post.subscriptionid > 0
              ? __("Unsubscribe from the author")
              : __("Subscribe to the author")
            ).toLowerCase(),
            click: async () => {
              const token = getCookie("token");
              const userid = parseInt(getCookie("userid"));
              const data = await window.TALKVIOAPI("subscribe", {
                userid,
                token,
                targetuserid: post.userid,
              });
              window.displayError(data);
            },
          },
        user?.userid > 0 &&
          post.parentid == 0 && {
            name: (post.subscribethreadid > 0
              ? __("Unsubscribe from the post updates")
              : __("Subscribe to the post updates")
            ).toLowerCase(),
            click: async () => {
              const token = getCookie("token");
              const userid = parseInt(getCookie("userid"));
              const data = await window.TALKVIOAPI("subscribeThread", {
                userid,
                token,
                threadid: post.threadid,
              });
              window.displayError(data);
            },
          },
        user?.userid > 0 && {
          name: (post.bookmarkid > 0
            ? __("Delete from bookmarks")
            : __("To the bookmarks")
          ).toLowerCase(),
          click: async () => {
            const token = getCookie("token");
            const userid = parseInt(getCookie("userid"));
            const data = await window.TALKVIOAPI("bookmark", {
              userid,
              token,
              postid: post.postid,
            });
            window.displayError(data);
          },
        },
      ]}
    >
      <FontAwesomeIcon icon={faEllipsis} />
    </Menu>
  );

  const searchSimilarThreads = async (text) => {
    if (!text || text.length < 3) {
      setConnectedPostSearchResults([]);
      return;
    }

    const data = await window.TALKVIOAPI("search", {
      titleText: text,
      simple: true,
    });

    if (data?.posts) {
      setConnectedPostSearchResults(data.posts);
    } else {
      setConnectedPostSearchResults([]);
    }
  };

  if (user?.blockusers && post.userid != user?.userid) {
    if (
      user.blockusers
        .split(",")
        .map((i) => i | 0)
        .includes(post.userid)
    ) {
      logT(
        "post",
        "ignore this post",
        post.postid,
        "because blocked user",
        post.userid,
        "user",
        post.username,
      );
      return null;
    }

    // quote must not be displayed for such cases
    if (
      post.parentuserid > 0 &&
      user.blockusers
        .split(",")
        .map((i) => i | 0)
        .includes(post.parentuserid)
    ) {
      logT(
        "post",
        "ignore this post",
        post.postid,
        "because blocked parent user",
        post.parentuserid,
        "user",
        post.username,
        "userid",
        post.userid,
      );
      return null;
    }
  }

  if (user?.blocktags && post.userid != user?.userid) {
    const blockTags = user.blocktags.split(",");
    if (blockTags.length > 0) {
      if (
        blockTags.some((tag) =>
          tags
            ?.split(",")
            .map((t) => t.trim())
            .includes(tag),
        )
      ) {
        logT(
          "post",
          "ignore this post",
          post.postid,
          "because blocked",
          blockTags,
          "tags",
          tags.split(","),
          "user",
          post.username,
        );
        return null;
      }
    }
  }

  return (
    <>
      <li
        className={
          "postBody" +
          (leftRatingDisplay ? " withLeftContainer" : "") +
          (!treeRoot ? " tree" : "")
        }
        id={"post_" + post.postid}
        data-postid={enableDataPostid ? post.postid : null}
        itemScope
        itemType="http://schema.org/DiscussionForumPosting"
      >
        {leftRatingDisplay && !sticky ? (
          <div className="leftPostPanel">
            <div ref={leftFollower} className="leftPostFollower header-related">
              <div className="ratingLeftChanger">
                <div className="raitingContainer">
                  <div
                    onClick={async () => {
                      const token = getCookie("token");
                      const userid = parseInt(getCookie("userid"));
                      if (userid && token) {
                        const data = await window.TALKVIOAPI(
                          "changePostRating",
                          {
                            userid,
                            token,
                            postid: post.postid,
                            reduce: false,
                          },
                        );
                        window.displayError(data);
                      } else {
                        if (window.needRegistationAction)
                          window.needRegistationAction();
                      }
                    }}
                    className={"up" + (post.setrating == "+" ? " current" : "")}
                  >
                    <div className="rating-clicker"></div>
                    <svg viewBox="0 0 123.959 123.959">
                      <path
                        d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742
                                    z"
                      />
                    </svg>
                  </div>
                  <div
                    className={
                      "rating" +
                      (post.setrating == "+" ? " pos" : "") +
                      (post.setrating == "-" ? " neg" : "")
                    }
                  >
                    {post.postrating}
                  </div>
                  <div
                    onClick={async () => {
                      const token = getCookie("token");
                      const userid = parseInt(getCookie("userid"));
                      if (userid && token) {
                        const data = await window.TALKVIOAPI(
                          "changePostRating",
                          {
                            userid,
                            token,
                            postid: post.postid,
                            reduce: true,
                          },
                        );
                        window.displayError(data);
                      } else {
                        if (window.needRegistationAction)
                          window.needRegistationAction();
                      }
                    }}
                    className={
                      "down" + (post.setrating == "-" ? " current" : "")
                    }
                  >
                    <div className="rating-clicker"></div>
                    <svg transform="rotate(180)" viewBox="0 0 123.959 123.959">
                      <path
                        d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742
                                    z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {setHidden && (
                <div
                  className="hideBox"
                  onClick={() => {
                    if (setHidden) setHidden(!hidden);
                  }}
                >
                  {hidden ? "+" : "-"}
                </div>
              )}
            </div>
            {onNextPostScroll ? (
              <div
                className="scrollBotommer"
                onClick={() => {
                  if (onNextPostScroll) onNextPostScroll();
                }}
              ></div>
            ) : null}
          </div>
        ) : null}
        <div
          className={
            "post" +
            (minimal ? " minimal" : "") +
            (sticky ? " sticky" : "") +
            (hidden ? " hidden" : "") +
            (!treeRoot ? " tree" : "") +
            (preview ? " preview" : "")
          }
        >
          {post.parentid == 0 ? (
            <>
              <meta
                itemProp="url"
                content={`https://talkvio.com/threads/${post.threadid}-${transliterateLink(post.threadtitle)}`}
              />
              <meta itemProp="headline" content={post.threadtitle} />
            </>
          ) : (
            <>
              <meta
                itemProp="url"
                content={`https://talkvio.com/post/${post.postid}`}
              />
              <meta
                itemProp="headline"
                content={`Комментарий пользователя ${post.username} на пост ${post.threadtitle}`}
              />
            </>
          )}
          <input type="hidden" name="postid" value={post.postid} />
          <input type="hidden" name="username" value={post.username} />
          <input type="hidden" name="userid" value={post.userid} />
          <input
            type="hidden"
            name="avatarrevision"
            value={post.avatarrevision}
          />
          <div className="postcore">
            <div className="userleft">
              <div className="avatar">
                <Link to={"/user/" + post.userid}>
                  <img
                    src={
                      post.avatarrevision > 0
                        ? `https://talkvio.com/customavatars/avatar${post.userid}_${post.avatarrevision}.gif`
                        : DefaultAvatar_150
                    }
                    alt={"Аватар пользователя " + post.username}
                  />
                </Link>
              </div>
              <div className="topinfo">
                <div className="user-main-block">
                  <div
                    className="username"
                    itemProp="author"
                    itemScope
                    itemType="http://schema.org/Person"
                  >
                    <meta
                      itemProp="url"
                      content={`https://talkvio.com/${post.username}`}
                    />
                    <Link to={"/user/" + post.userid}>
                      <span itemProp="name">{post.username}</span>
                      <sup className="gender">
                        {post.gender == "male" ? (
                          <FontAwesomeIcon className="male" icon={faMars} />
                        ) : null}
                        {post.gender == "female" ? (
                          <FontAwesomeIcon className="female" icon={faVenus} />
                        ) : null}
                      </sup>
                    </Link>
                  </div>
                  {post.usertitle && (
                    <div className="usertitle">
                      <span>• {post.usertitle}</span>
                    </div>
                  )}
                </div>
                {!sticky ? (
                  <div className="user-desc-block">
                    {post.parentid == 0 && forum && forum?.title?.length > 0 ? (
                      <div className="forum">
                        <Link to={"/section/" + forum.forumid}>
                          {forum.title}
                        </Link>
                      </div>
                    ) : null}
                    <meta
                      itemProp="datePublished"
                      content={moment(post.dateline * 1000).format()}
                    />
                    <div className="date">
                      <Tooltip
                        width={140}
                        hint={moment(post.dateline * 1000).format(
                          "D MMM YYYY в HH:mm",
                        )}
                      >
                        {timeAgo(post.dateline * 1000)}
                      </Tooltip>
                    </div>
                    {post.parentid != 0 && post.userid == post.threaduserid ? (
                      <div className="author">
                        <span>• {__("author")}</span>
                      </div>
                    ) : null}
                    {post.private ? (
                      <div className="private">
                        <div className="private-text-container">
                          <span className="private-text">
                            • <FontAwesomeIcon icon={faLock} />
                          </span>
                        </div>
                        <Link
                          className="private-user"
                          to={`/user/${post.parentuserid}`}
                        >
                          <div className="userReplyName-container">
                            <div className="userReplyName">
                              {post.parentusername}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {leftRatingDisplay &&
              user?.settings?.topRatingDisplay &&
              !sticky ? (
                <div className="ratingCenterChanger">
                  <div>
                    <div
                      onClick={async () => {
                        const token = getCookie("token");
                        const userid = parseInt(getCookie("userid"));
                        if (userid && token) {
                          const data = await window.TALKVIOAPI(
                            "changePostRating",
                            {
                              userid,
                              token,
                              postid: post.postid,
                              reduce: false,
                            },
                          );
                          window.displayError(data);
                        } else {
                          if (window.needRegistationAction)
                            window.needRegistationAction();
                        }
                      }}
                      className={
                        "up" + (post.setrating == "+" ? " current" : "")
                      }
                    >
                      <svg viewBox="0 0 123.959 123.959">
                        <path
                          d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742
                                            z"
                        />
                      </svg>
                    </div>
                    <div
                      className={
                        "rating" +
                        (post.setrating == "+" ? " pos" : "") +
                        (post.setrating == "-" ? " neg" : "")
                      }
                    >
                      {post.postrating}
                    </div>
                    <div
                      onClick={async () => {
                        const token = getCookie("token");
                        const userid = parseInt(getCookie("userid"));
                        if (userid && token) {
                          const data = await window.TALKVIOAPI(
                            "changePostRating",
                            {
                              userid,
                              token,
                              postid: post.postid,
                              reduce: true,
                            },
                          );
                          window.displayError(data);
                        } else {
                          if (window.needRegistationAction)
                            window.needRegistationAction();
                        }
                      }}
                      className={
                        "down" + (post.setrating == "-" ? " current" : "")
                      }
                    >
                      <svg
                        transform="rotate(180)"
                        viewBox="0 0 123.959 123.959"
                      >
                        <path
                          d="M66.18,29.742c-2.301-2.3-6.101-2.3-8.401,0l-56,56c-3.8,3.801-1.1,10.2,4.2,10.2h112c5.3,0,8-6.399,4.2-10.2L66.18,29.742
                                            z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ) : null}
              {!sticky && (
                <div className="postidshow">
                  <RightPostMenu />
                </div>
              )}
            </div>
            <div className="textcenter" ref={postTextRef}>
              {post.visible === 0 ? (
                <Tooltip
                  hint={__(
                    "This post in not published and visible only for you",
                  )}
                >
                  <div className="visibleStatus">
                    ({__("post unpublished")})
                  </div>
                </Tooltip>
              ) : null}
              {(post.parentid == 0 || forceTitle) &&
              post.threadtitle?.length > 0 &&
              !edit ? (
                <div
                  className={
                    "postTitle" + (post.parentid != 0 ? " replyTitle" : "")
                  }
                >
                  {post.parentid != 0 ? (
                    <span className="replyIcon">
                      <FontAwesomeIcon icon={faReplyAll} />
                    </span>
                  ) : null}
                  <Link
                    className="postTitleLink"
                    to={
                      postLink
                        ? `/post/${post.postid}`
                        : "/threads/" +
                          post.threadid +
                          `-${transliterateLink(post.threadtitle)}`
                    }
                  >
                    {post.original || post.threadoriginal ? (
                      <span className="mine">[моё] </span>
                    ) : (
                      ""
                    )}
                    {post.threadtitle}
                  </Link>
                  {post.similarity ? (
                    <div className="similarityValue">
                      {Math.round(post.similarity * 100)}%
                    </div>
                  ) : null}
                </div>
              ) : null}
              {!edit ? (
                <>
                  {!pageObj ? (
                    <div
                      itemProp="articleBody"
                      className="pagetext oldformat"
                      dangerouslySetInnerHTML={{
                        __html: parsePost(post.pagetext),
                      }}
                    />
                  ) : !hidden ? (
                    <>
                      {post.parent &&
                      !sticky &&
                      treeRoot &&
                      post.parentlevel >= 2 &&
                      post.parentuserid != post.userid ? (
                        <div className="post-quote">
                          <div className="post-quote-deep column flex wrap">
                            <Link to={"/user/" + post.parentuserid}>
                              <div className="userReplyImg flex row center-column">
                                <img
                                  src={
                                    post.parentavatarrevision > 0
                                      ? `https://talkvio.com/customavatars/avatar${post.parentuserid}_${post.parentavatarrevision}.gif`
                                      : DefaultAvatar_150
                                  }
                                  alt={
                                    __("Avatar of user") +
                                    " " +
                                    post.parentusername
                                  }
                                />
                                <div className="userReplyName">
                                  {post.parentusername}
                                </div>
                              </div>
                            </Link>
                            <Article
                              className={
                                post.adult && !showAdultPost ? "hideAdult" : ""
                              }
                              quote={true}
                              data={articleObject(post.parent.pagetext)}
                              maxTextSize={100}
                              maxTextShowOnClick={true}
                              textFullQuote={textFullQuote}
                              onTextFullQuote={onTextFullQuote}
                            />
                          </div>
                        </div>
                      ) : null}
                      <Article
                        className={
                          post.adult && !showAdultPost ? "hideAdult" : ""
                        }
                        data={pageObj}
                        collapsable={collapsable}
                        onCollapsed={onCollapsed}
                        onUncollapse={onUncollapse}
                        uncollapseDefault={uncollapse}
                        noFirstImageAdult={noFirstImageAdult}
                        onFirstImage={onFirstImage}
                        onFirstTextBlock={onFirstTextBlock}
                        onFirstYoutubeBlock={onFirstYoutubeBlock}
                        onFirstVideoPreviewImage={onFirstVideoPreviewImage}
                        filterType={sticky && ["text"]}
                        maxTextSize={maxTextSize}
                        convertToText={sticky}
                        description={post.threadtitle}
                        dateline={post.dateline}
                        onRenderFinish={onRenderFinish}
                        preview={preview}
                      />
                      {post.adult && !showAdultPost ? (
                        <>
                          {!sticky ? (
                            <div
                              className="showAdultButton"
                              onClick={() => setShowAdultPost(true)}
                            >
                              Показать контент 18+
                            </div>
                          ) : (
                            <div className="pagetext article">
                              <div className="block text">
                                <i>(контент 18+)</i>
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                      {post.taglist?.length > 0 &&
                      post.parentid == 0 &&
                      !sticky ? (
                        <div className="taglist">
                          {post.taglist.split(", ").map((tag) => (
                            <div className="tag">
                              <MouseFocusContainer
                                condition={!!user}
                                menu={
                                  <Menu
                                    items={[
                                      user &&
                                      user.blocktags?.split(",").includes(tag)
                                        ? {
                                            name: __("Unblock"),
                                            click: async (close) => {
                                              const token = getCookie("token");
                                              const userid = parseInt(
                                                getCookie("userid"),
                                              );
                                              const data =
                                                await window.TALKVIOAPI(
                                                  "blockTag",
                                                  {
                                                    userid,
                                                    token,
                                                    tag,
                                                  },
                                                );
                                              window.displayError(data);
                                              close();
                                            },
                                          }
                                        : {
                                            name: __("Block"),
                                            click: async (close) => {
                                              const token = getCookie("token");
                                              const userid = parseInt(
                                                getCookie("userid"),
                                              );
                                              const data =
                                                await window.TALKVIOAPI(
                                                  "blockTag",
                                                  {
                                                    userid,
                                                    token,
                                                    tag,
                                                  },
                                                );
                                              window.displayError(data);
                                              close();
                                            },
                                          },
                                    ]}
                                  />
                                }
                              >
                                <Link to={"/tag/" + tag}>#{tag}</Link>
                              </MouseFocusContainer>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {!sticky && !minimal && post.parentid == 0 ? (
                        <Tooltip
                          hint={
                            __("Ratio of positive and negative votes") +
                            ": " +
                            post.rating_good +
                            "/" +
                            post.rating_bad
                          }
                        >
                          <div className="flex row center-column post-rating-progress-bar">
                            <LinearProgress
                              className="post-rating-progress"
                              color={window.darkMode ? "#1eb32b" : "#31c48d"}
                              backgroundColor="#ed3b50"
                              value={
                                post.rating_good + post.rating_bad > 0
                                  ? (post.rating_good /
                                      (post.rating_good + post.rating_bad)) *
                                    100
                                  : 100
                              }
                            />
                            <div
                              className={
                                "percent-display" +
                                ((post.rating_good + post.rating_bad > 0
                                  ? (post.rating_good /
                                      (post.rating_good + post.rating_bad)) *
                                    100
                                  : 100) >= 50
                                  ? " rating-good"
                                  : " rating-bad")
                              }
                            >
                              {post.rating_good + post.rating_bad > 0
                                ? ((post.rating_good /
                                    (post.rating_good + post.rating_bad)) *
                                    100) |
                                  0
                                : 100}
                              %<span> / </span>
                              <span className="rating-count ratingGood">
                                <FontAwesomeIcon icon={faCaretUp} />
                                {post.rating_good}
                              </span>
                              <span> / </span>
                              <span className="rating-count ratingBad">
                                <FontAwesomeIcon icon={faCaretDown} />
                                {post.rating_bad}
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                      ) : null}
                      {!sticky &&
                      !minimal &&
                      post.parentid == 0 &&
                      post.karma >= 3 &&
                      post.userShowMySocials ? (
                        <UserSocials
                          myUser={user}
                          user={{
                            discord: post.userDiscord,
                            telegram: post.userTelegram,
                            homepage: post.userHomepage,
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {post.parentid == 0 ? (
                    <div className="forumName">
                      {selectionForum?.forumid ? (
                        <>
                          <div className="selectedForumDesc">
                            {__("Section")}:{" "}
                          </div>
                          <div className="selectedForum">
                            {selectionForum.title}
                          </div>
                          <div
                            className="selectedForumRemove"
                            onClick={() => {
                              setSelectionForum(null);
                              setSelectionForumTitle(null);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </>
                      ) : (
                        <Input
                          placeholderLeft={true}
                          placeholder={__("Section")}
                          value={selectionForumTitle}
                          onChange={async (text) => {
                            setSelectionForumTitle(text);
                            if (searchForumTimeout)
                              clearTimeout(searchForumTimeout);
                            searchForumTimeout = setTimeout(async () => {
                              searchForum(text);
                            }, 310);
                          }}
                        ></Input>
                      )}
                      <div className="forumSelection">
                        <div className="forumSelectionIcon">
                          <FontAwesomeIcon icon={faList} />
                        </div>
                        <select
                          value={JSON.stringify(
                            selectionForum || { forumid: 0 },
                          )}
                          onChange={(event) => {
                            const forum = JSON.parse(event.target.value);
                            setSelectionForum(forum);
                            setSelectionForumTitle(forum.title);
                          }}
                        >
                          <option value={JSON.stringify({ forumid: 0 })}>
                            ({__("Don't select")})
                          </option>
                          {forums?.length > 0
                            ? forums.map((forum, i) => (
                                <option key={i} value={JSON.stringify(forum)}>
                                  {forum.title}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {searchForumList?.length > 0 ? (
                    <ul className="forumSearchList">
                      {searchForumList.map((forum) => (
                        <li
                          key={forum.forumid}
                          onClick={() => {
                            setSearchForumList([]);
                            setSelectionForum(forum);
                            setSelectionForumTitle(forum.title);
                          }}
                        >
                          {forum.title}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {edit && post.parentid == 0 ? (
                    <div className="threadInputName">
                      <input
                        placeholder={__("Title")}
                        value={threadTitle}
                        onInput={(e) => {
                          setThreadTitle(e.target.value);
                        }}
                      />
                    </div>
                  ) : null}
                  {
                    <ArticleEditor
                      temporary={true}
                      setData={setPageObj}
                      data={
                        pageObj || {
                          userid: post.userid,
                          blocks: [
                            { type: "text", data: parsePost(post.pagetext) },
                          ],
                        }
                      }
                    />
                  }
                  {edit && post.parentid == 0 ? (
                    <>
                      <div className="tagsInput">
                        <input
                          placeholder={__(
                            "Please add tags seprarated by commas",
                          )}
                          value={tags}
                          ref={tagsInput}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              if (tabTagTimeout.current) {
                                logT("key", "clear timeout tag");
                                clearTimeout(tabTagTimeout.current);
                              }
                            } else if (e.key === "Tab") {
                              e.preventDefault();
                              logT("key", "autocomplete tag");

                              let text = tagsInput.current.value;
                              if (!text) return;
                              if (!searchTagList?.length) return;
                              const tag =
                                searchTagList[tabTagIndex.current || 0];
                              if (!tag) return;
                              tabTagIndex.current =
                                (tabTagIndex.current + 1) %
                                searchTagList.length;
                              text = text.split(",").map((tag) => tag.trim());
                              logT(
                                "pattern",
                                tabTagPattern.current || tag.pattern,
                                "replace with",
                                tag.tagtext,
                              );
                              text[text.length - 1] = text[
                                text.length - 1
                              ].replace(
                                tabTagPattern.current || tag.pattern,
                                tag.tagtext,
                              );
                              tabTagPattern.current = tag.tagtext;
                              text = text.join(", ");
                              tagsInput.current.value = text;
                              setTags(text);

                              ignoreTagList.push(tag.tagtext);
                              if (tabTagTimeout.current)
                                clearTimeout(tabTagTimeout.current);
                              tabTagTimeout.current = setTimeout(() => {
                                tabTagIndex.current = 0;
                                tabTagPattern.current = null;
                                tabTagTimeout.current = null;
                                let text = tagsInput.current.value;
                                text += ", ";
                                tagsInput.current.value = text;
                                setTags(text);
                                logT("pattern", "clear setSearchTagList");
                                setSearchTagList([]);
                              }, 1000);
                            }
                          }}
                          onInput={async (event) => {
                            const text = event.target.value;
                            setTags(text);
                            if (text) {
                              const textSplit = text
                                .split(",")
                                .map((tag) => tag.trim());
                              if (textSplit.length > 0) {
                                tabTagPattern.current =
                                  textSplit[textSplit.length - 1];
                              }
                            }
                            if (searchTagTimeout.current)
                              clearTimeout(searchTagTimeout.current);
                            searchTagTimeout.current = setTimeout(async () => {
                              if (!text || text.length == 0) {
                                setSearchTagList([]);
                                return;
                              }

                              let tagMatch = /(?:^|,)[\s]*([^,$]+)/g;
                              let match;
                              const foundList = [];
                              while ((match = tagMatch.exec(text)) !== null) {
                                if (ignoreTagList.includes(match[1])) {
                                  continue;
                                }
                                foundList.push(match[1]);
                              }
                              if (foundList.length > 0) {
                                searchTag(foundList[foundList.length - 1]);
                              }
                            }, 310);
                          }}
                        />
                      </div>
                      {searchTagList && searchTagList.length > 0 ? (
                        <ul className="tagSearchList">
                          {searchTagList.map((tag) => (
                            <li
                              key={tag.tagid}
                              onClick={() => {
                                let text = tagsInput.current.value;
                                if (!text) return;
                                text = text.split(",").map((tag) => tag.trim());
                                text[text.length - 1] =
                                  text[text.length - 1].replace(
                                    tabTagPattern.current || tag.pattern,
                                    tag.tagtext,
                                  ) + ", ";
                                text = text.join(", ");
                                tagsInput.current.value = text;
                                setTags(text);

                                tabTagPattern.current = null;
                                ignoreTagList.push(tag.tagtext);
                                setSearchTagList([]);
                              }}
                            >
                              #{tag.tagtext} ({tag.usecount})
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {post.parentid == 0 ? (
                        <div className="connectedPostsBlock">
                          {connectedPosts?.length > 0 &&
                          connectedPosts[0].threadid != -1 ? (
                            <div className="connectedPostsTitle">
                              {__("Connected posts")}:
                            </div>
                          ) : null}
                          {connectedPosts?.length > 0 &&
                          connectedPosts[0].threadid != -1 ? (
                            <div className="connectedPosts">
                              {connectedPosts.map((connectedPost) => (
                                <ConnectedPost
                                  key={connectedPost.postid}
                                  post={connectedPost}
                                  onRemove={() => {
                                    setConnectedPosts(
                                      connectedPosts.filter(
                                        (p) =>
                                          p.postid !== connectedPost.postid,
                                      ),
                                    );
                                  }}
                                />
                              ))}
                            </div>
                          ) : null}
                          <div className="addConnectedPost">
                            <Input
                              placeholder={__("Enter post link")}
                              placeholderLeft={true}
                              placeholderLeftText={__("Connect the post")}
                              value={connectedPostLink}
                              onChange={(value) => {
                                setConnectedPostLink(value);
                                if (searchConnectedPostTimeout.current) {
                                  clearTimeout(
                                    searchConnectedPostTimeout.current,
                                  );
                                }
                                searchConnectedPostTimeout.current = setTimeout(
                                  () => {
                                    searchSimilarThreads(value);
                                  },
                                  300,
                                );
                              }}
                              onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                  addConnectedPost();
                                }
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faLink}
                              onClick={() => addConnectedPost()}
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {connectedPostSearchResults.length > 0 && (
                    <ul className="connectedPostSearchList">
                      {connectedPostSearchResults.map((searchResult) => (
                        <li
                          key={searchResult.id}
                          onClick={async () => {
                            const url = `https://talkvio.com/threads/${searchResult.id}-${searchResult.title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`;
                            setConnectedPostSearchResults([]);
                            await addConnectedPost(url);
                          }}
                        >
                          {searchResult.title}
                        </li>
                      ))}
                    </ul>
                  )}
                  {post.parentid == 0 ? (
                    <div className="controls">
                      <Checkbox
                        value={originalContent}
                        onSwitch={(checked) => setOriginalContent(checked)}
                        text={__("Author's / original content (mine)")}
                      />
                      <Checkbox
                        value={adult}
                        onSwitch={(checked) => setAdult(checked)}
                        text={__("Adult content / 18+")}
                      />
                      {post.dateline * 1000 > Date.now() ? (
                        <Tooltip
                          hint={__("Set date when post will be published")}
                        >
                          <Checkbox
                            text={__("Publication schedule")}
                            value={schedulePublication}
                            onSwitch={(checked) =>
                              setScedulePublication(checked)
                            }
                          />
                        </Tooltip>
                      ) : null}
                      {schedulePublication ? (
                        <DtPicker
                          showTimeInput={true}
                          autoClose={false}
                          withTime={true}
                          initValue={
                            scheduleTimeProtector?.current ||
                            dateToDateObject(scheduleTime)
                          }
                          onChange={(date) => {
                            if (
                              !_.isEqual(date, scheduleTimeProtector?.current)
                            ) {
                              const time = dateObjectToDate(date);
                              logT("calendar", "choose", time);
                              scheduleTimeProtector.current = date;
                              setSceduleTime(time);
                            } else {
                              logT("calendar", "ignore same value", date);
                            }
                          }}
                          minDate={dateToDateObject(new Date())}
                          placeholder={__("select time")}
                          nextMonthBtnTitle={__("next")}
                          previousMonthBtnTitle={__("previous")}
                          fromLabel={__("from")}
                          toLabel={__("to")}
                          clockFromLabel={__("from")}
                          clockToLabel={__("to")}
                          clockLabel={__("clock")}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </>
              )}
              {!minimal && !sticky ? (
                <div className="downinfo">
                  <BottomControls />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {minimal && !sticky ? (
            <div className="bottomStats">
              <BottomControls />
            </div>
          ) : null}

          {tree &&
          (typeof treeMaxLevel == "undefined" || treeMaxLevel > 0) &&
          post.childs &&
          Object.keys(post.childs).length > 0 ? (
            <div className="poststree">
              <div className="collapser">
                <div className="collapser-line"></div>
              </div>
              <div className="treeroot">
                {Object.values(post.childs).map((childPost) => (
                  <Post
                    source={source}
                    post={childPost}
                    key={childPost.postid}
                    user={user}
                    forum={forum}
                    fetchPosts={fetchPosts}
                    leftRatingDisplay={false}
                    postLink={postLink}
                    sticky={sticky}
                    maxTextSize={maxTextSize}
                    onReplyClick={onReplyClick}
                    showReply={showTreeReply}
                    tree={tree}
                    treeMaxLevel={treeMaxLevel ? treeMaxLevel - 1 : undefined}
                    treeRoot={false}
                    replyAfterPostid={replyAfterPostid}
                    replyAfterPostidUniqueId={replyAfterPostidUniqueId}
                    replyPostEnabled={replyPostEnabled}
                    setReplyPostEnabled={setReplyPostEnabled}
                    onReply={onReply}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </li>
      {user?.userid > 0 &&
      ((replyAfterPostid == post.postid &&
        (!replyAfterPostidUniqueId ||
          replyAfterPostidUniqueId == postUniqueId.current)) ||
        showReplyForm) ? (
        <ReplyForm
          key={"reply-post-" + post.postid}
          className={"typicalBlock" + (!treeRoot ? " treeLeaf" : "")}
          replyPost={post}
          user={user}
          threadid={post.threadid}
          replyPostEnabled={replyPostEnabled}
          setReplyPostEnabled={setReplyPostEnabled}
          onReply={onReply}
          minimal={replyMinimal}
          textHintMinimal={replyMinimalTextHint}
        />
      ) : null}
    </>
  );
};
export default Post;
