import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { LoginContext } from "./Login";
import Post from "./Post";
import ReplyForm from "./ReplyForm";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "../global/Global";
import ImageEidorPostMark from "./editor-1.png";

const SpecialPage = ({ page }) => {
  if (page == "contest") {
    setCookie("contester", true, 30);
  }

  useEffect(() => {
    if (page == "contest") {
      document.title = `Talkvio - ${__("Contest") + " #3"}`;
    }
    if (page == "adscontent") {
      document.title = `Talkvio - Content creator`;
    }
  }, []);

  return (
    <div className="normalBackground">
      <div className="specialPage">
        {page == "adscontent" ? (
          <div>
            Ищем человека готового заниматься постингом на данном ресурсе и
            заполнением контентом (редактора). Будет необходимо создавать статьи
            или же комментировать существующие. Тематика текстов разнообразная:
            новости, фильмы, компьютерные игры, спорт, путешествие, отдых, юмор
            и другие актуальные темы <br />
            <br />
            Что необходимо:
            <ul className="smallListPadding">
              <li>
                Зарегистрироваться на данном сайте{" "}
                <a href="https://talkvio.com/">talkvio.com</a> и осмотреться.
              </li>
              <li>
                Оповестите <a href="https://t.me/degitx">https://t.me/degitx</a>{" "}
                о никнейме аккаунта что вы завели и планируете использовать для
                размещения контента, что бы было представление о том, что ваш
                аккаунт отличается от обычного пользователя ресурса.
              </li>
              <li style={{ marginLeft: "2em" }}>
                Желательно сразу оговорить примерные сроки, тип сотрудничества,
                сколько по времени вы планируете размещать контент, какой
                тематики.
              </li>
              <li style={{ marginLeft: "2em" }}>
                Вы можете продолжать пользоваться ресурсом как пользователь в
                свое удовольствие и по истечению сроков работы, так что
                обязательно оповестите об окончании работы/сотрудничества в
                качестве редактора, или о желании его возобновлении.
              </li>
              <li>
                Создавать статьи (посты) на различные тематики и актуальные темы
              </li>
              <li style={{ marginLeft: "2em" }}>
                Для этого после регистрации доступен редактор (в темах, в
                форумах, по знаку + в шапке)
              </li>
              <li style={{ marginLeft: "2em" }}>
                Посты могут быть различного размера, желательно начиная от 160
                символов, содержать картинки и youtube-видео, и на различные
                темы. Но содержание их должно быть естественным. К примеру, если
                вы создали пост (тему) с названием "Need for Speed: Most Wanted
                - лучшая аркадная автогонка из тех что играл", то обязательно
                напишите несколько предложений, или хотя бы абзац с тем почему
                вы так считаете и желательно прикрепите картинку с самой игрой.
                Т.е. халява, спам, и флуд лишь бы заполнить хоть каким-то
                бездумным контентом оплачиваться не будет.
              </li>
              <li style={{ marginLeft: "2em" }}>
                <b>
                  Обязательно учитывайте контент что уже размещен на форуме, ваш
                  контент должен быть новым, еще не размещавшимся на ресурсе.
                </b>
              </li>
              <li style={{ marginLeft: "4em" }}>
                (Рекомендация) Больше всего тут сохранилось старого контента
                периода 2007-2013 годов. Так что у вас есть возможность
                восполнить пробелы.
              </li>
              <li>
                Комментировать уже существующие свои темы, либо темы других
                людей.
              </li>
              <li style={{ marginLeft: "2em" }}>
                Для отслеживания постов, комментариев существуют кнопки на
                панели (Топ, Актуальное)
              </li>
              <li>
                <a href="https://talkvio.com/threads/24705-novii-dvizhok-talkvio-predlozheniyaobsuzhdenie">
                  В специальном разделе по доработкам
                </a>
                , по возможности, оставлять фидбек о трудностях, если таковые
                имеются с использованием ресурса.
              </li>
              <li>
                (Опционально) Поддерживать другую активность: читать темы других
                пользователей, лайкать/дизлайкать что вам нравится и т.п.
              </li>
              <li>
                Предлагать статьи, и тем более комментарии на какую-либо
                проверку/модерацию не нужно. Вы сами контролируете свой контент
                и наполнение. Ресурс базирован на форуме - если вы общались на
                таких ресурсах как форумы или pikabu - вы прекрасно понимаете
                какой вид наполнения постов и комментариев там чаще всего
                встречается. Тут тоже самое - вы работаете от своего аккаунта
                (или другими словами от самого себя).
              </li>
              <li>
                (Опционально) По возможности избегать постинга политического
                контента. Будь то посты или комментарии.
              </li>
            </ul>
            Что предлагаем:
            <ul className="smallListPadding">
              <li>
                <b>
                  За уникальные посты 30-70RUB (в зависимости от качества,
                  уникальности, и размера контента)
                </b>{" "}
                [Можно обсудить]
              </li>
              <li>
                <b>За комментарии 10-15RUB</b> [Можно обсудить]
              </li>
              <li style={{ marginLeft: "2em" }}>
                (Пометка) Существует базовый максимум (ограничение) на
                количество редакторских постов и комментариев в день [по
                умолчанию 5/20 соотвественно]. При выходе из него пост или
                комментарий будет считаться обычным. Данный лимит имеет
                возможность расширения в зависимости от набора специального
                алгоритмического рейтинга (кармы) или оговорен заранее. Всегда
                можно удостовериться является ли пост/комментарий редакторским
                по специальной иконке внизу в нижней части своего поста:
                <br />
                <img src={ImageEidorPostMark} alt="EditorPostMark" />
              </li>
              <li style={{ marginLeft: "2em" }}>
                Количество символов на данный момент никак не влияет на цену за
                комментарий или пост. Цена определяется из общего расчета{" "}
                <i>(в указанном выше минимальном интервале)</i> интересности
                постов и статей авторов и расчитывается по единому тарифу. Т.е.
                маленький интересный пост может принести больше чем нудная
                статья копипаста которая будет никому не интересна, но длинные
                интересные посты и комментарии приветствуются.
              </li>
              <li>
                В вашем редакторском профиле на сайте отображается накопленная
                сумма, вы всегда можете свериться с ней.
              </li>
              <li>
                Возможны долговременное сотрудничество, если понравится ресурс и
                работа в качестве редактора.
              </li>
              <li>
                По умолчанию предлагается такая схема оплаты: можно списываться
                раз в месяц или 2 для подсчета статистики и отправки оплаты по
                проделанной работе. В первый раз можно через 2-3 дня, чтобы была
                уверенность что все ок. Оплата как правило происходит напрямую
                на карту.
              </li>
              <li style={{ marginLeft: "2em" }}>
                О готовности принять оплату нужно сообщать на{" "}
                <a href="https://t.me/degitx">https://t.me/degitx</a> лично со
                своей стороны. Т.к. на сайте работает не один редактор а много,
                желательно писать насчет этого самому.
              </li>
              <li style={{ marginLeft: "2em" }}>
                В случае изменения каких либо условий с редактором/постером
                контента свяжутся и оповестят об изменениях.
              </li>
              <li style={{ marginLeft: "2em" }}>
                Авторское право в рамках работы над контентом в форме редактора
                сохраняется как за вами, так и за ресурсом. Вы можете потом
                использовать контент для своих целей, так и ресурс может
                использовать для свои целей (например рекламы).
              </li>
            </ul>
          </div>
        ) : null}
        {page == "contest" ? (
          <div>
            <h2 className="title">Конкурс контента Talkvio #4</h2>
            Проводится конкурс #4 на лучший пост/комментарий на новом
            развлекательном портале Talkvio.
            <br />
            <br />
            Что необходимо:
            <ul className="smallListPadding">
              <li>
                <b>
                  Зарегистрироваться на данном сайте{" "}
                  <a href="https://talkvio.com/">talkvio.com</a> или через
                  android приложение и осмотреться.
                </b>
              </li>
              <li style={{ marginLeft: "2em" }}>
                После регистрации в вашем профиле должна отобразиться пометка о
                том что вы участник конкурса. Аккаунт будет зарегистрирован как
                конкурсный если вы предварительно зашли на сайт с этой страницы.
              </li>
              <li style={{ marginLeft: "4em" }}>
                Если вдруг вы не находите пометку (например при регистрации из
                приложения). Зайдите в настройки своего аккаунта, найдите поле
                "Консоль", введите в поле "contest4" и нажмите enter. Ваш
                аккаунт будет зарегистрирован на конкурсе. Вы можете
                перепроверить это на странице пользователя.
              </li>
              <li style={{ marginLeft: "2em" }}>
                Вы можете использовать клиент приложения на Android если вам
                удобнее:{" "}
                <a href="https://play.google.com/store/apps/details?id=com.talkvio.app">
                  https://play.google.com/store/apps/details?id=com.talkvio.app
                </a>
              </li>
              <li style={{ marginLeft: "2em" }}>
                Если у вас возникают сложности с ресурсом, или вы найдете
                недоработку, пожалуйста опишите их в специальном разделе -{" "}
                <a href="https://talkvio.com/forums/48">
                  https://talkvio.com/forums/48
                </a>
                . Там так же можно внести предложения по улучшению Talkvio.
                Помощь по использованию ресурсу -{" "}
                <a href="/threads/29467-rukovodstvo-po-talkvio">
                  https://talkvio.com/threads/29467-rukovodstvo-po-talkvio
                </a>
              </li>
              <li>
                <b>
                  Написать 5 разнообразных постов или больше на любую интересную
                  вам тему.
                </b>
              </li>
              <li>
                <b>
                  Написать 5 разнообразных комментариев или больше под любыми
                  постами.
                </b>
              </li>
            </ul>
            <div>
              Конкурс продлится до 01 мая 2025 года. До 07.05.2025 будут
              определены победители. Призы выданы до 14.05.2025. После чего
              будет объявлен победитель. Администрация выберет самый хороший
              пост и объявит победителя. Какими критериями будем
              руководствоваться:
            </div>
            <ul className="smallListPadding">
              <li>По рейтингу поста на ресурсе. (первостепенно)</li>
              <li>
                По количеству оставленных комментариев под постом другими
                пользователями.
              </li>
              <li>
                Последующей активностью на ресурсе. Если человек не просто
                зарегистрировался а продолжит вести аккаунт - это будет большой
                плюс и весомый аргумент.
              </li>
            </ul>
            <div>Призы:</div>
            <ul className="smallListPadding">
              <li>
                <b>За лучший пост | автор получает 250$</b> - основной приз
                описанный в правилах выше.
              </li>
              <li>
                <b>Отдельный приз за активность на ресурсе | 100$</b> - самому
                активному новому пользователю ресурса будет вручен отдельный
                приз.
              </li>
              <li>
                <b>Лучшая доработка | 50$</b> - еще одна номинация, если
                пользователь будет сильно способствовать развитию ресурса - он
                получит этот приз.
              </li>
            </ul>
            <div>
              С автором свяжутся по зарегистрированному email на сайте, или
              автор может обратиться к организаторам.
            </div>
            <div>
              С вопросами поводу конкурса обращаться к{" "}
              <a href="https://t.me/degitx">https://t.me/degitx</a>
            </div>
            <br />
            <hr />
            <br />
            <div>
              Победители предыдущего 1 конкурса (результаты 07.05.2024):
            </div>
            <ul className="smallListPadding">
              <li>
                За лучший пост | автор получает 250$ -{" "}
                <Link to="/My Channel">My Channel</Link>
              </li>
              <li>
                Отдельный приз за активность на ресурсе | 100$ -{" "}
                <Link to="/Aiya">Aiya</Link>
              </li>
              <li>
                Лучшая доработка | 50$ - к сожалению не разыгран, было крайне
                мало предложений от всех участников.
              </li>
            </ul>
            <br />
            <hr />
            <br />
            <div>
              Победители предыдущего 2 конкурса (результаты 05.10.2024):
            </div>
            <ul className="smallListPadding">
              <li>
                За лучший пост | автор получает 250$ -{" "}
                <Link to="/My Channel">My Channel</Link> (опять :D)
              </li>
              <li>
                Отдельный приз за активность на ресурсе | 100$ -{" "}
                <Link to="/Aiya">Aiya</Link> (опять :D)
              </li>
              <li>
                Лучшая доработка | 50$ - к сожалению не разыгран, было крайне
                мало предложений от всех участников.
              </li>
            </ul>
            <br />
            <hr />
            <br />
            <div>
              Победители предыдущего 3 конкурса (результаты 23.01.2025):
            </div>
            <ul className="smallListPadding">
              <li>
                За лучший пост | автор получает 250$ -{" "}
                <Link to="/My Channel">My Channel</Link> (опять :D)
              </li>
              <li>
                Отдельный приз за активность на ресурсе | 100$ -{" "}
                <Link to="/Aiya">Aiya</Link> (опять :D)
              </li>
              <li>
                Лучшая доработка | 50$ - к сожалению не разыгран, было крайне
                мало предложений от всех участников.
              </li>
            </ul>
            <div>Большое спасибо всем участникам!</div>
          </div>
        ) : null}
        {page == "policy" ? (
          <div>
            Политика в отношении обработки персональных данных
            <br />
            1. Общие положения
            <br />
            Настоящая политика обработки персональных данных составлена в
            соответствии с требованиями Федерального закона от 27.07.2006. №
            152-ФЗ «О персональных данных» (далее — Закон о персональных данных)
            и определяет порядок обработки персональных данных и меры по
            обеспечению безопасности персональных данных, предпринимаемые
            Алексей Юнчук (далее — Оператор).
            <br />
            1.1. Оператор ставит своей важнейшей целью и условием осуществления
            своей деятельности соблюдение прав и свобод человека и гражданина
            при обработке его персональных данных, в том числе защиты прав на
            неприкосновенность частной жизни, личную и семейную тайну.
            <br />
            1.2. Настоящая политика Оператора в отношении обработки персональных
            данных (далее — Политика) применяется ко всей информации, которую
            Оператор может получить о посетителях веб-сайта https://talkvio.com.
            <br />
            2. Основные понятия, используемые в Политике
            <br />
            2.1. Автоматизированная обработка персональных данных — обработка
            персональных данных с помощью средств вычислительной техники.
            <br />
            2.2. Блокирование персональных данных — временное прекращение
            обработки персональных данных (за исключением случаев, если
            обработка необходима для уточнения персональных данных).
            <br />
            2.3. Веб-сайт — совокупность графических и информационных
            материалов, а также программ для ЭВМ и баз данных, обеспечивающих их
            доступность в сети интернет по сетевому адресу https://talkvio.com.
            <br />
            2.4. Информационная система персональных данных — совокупность
            содержащихся в базах данных персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств.
            <br />
            2.5. Обезличивание персональных данных — действия, в результате
            которых невозможно определить без использования дополнительной
            информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных.
            <br />
            2.6. Обработка персональных данных — любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием
            средств автоматизации или без использования таких средств с
            персональными данными, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных.
            <br />
            2.7. Оператор — государственный орган, муниципальный орган,
            юридическое или физическое лицо, самостоятельно или совместно с
            другими лицами организующие и/или осуществляющие обработку
            персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными.
            <br />
            2.8. Персональные данные — любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Пользователю веб-сайта
            https://talkvio.com.
            <br />
            2.9. Персональные данные, разрешенные субъектом персональных данных
            для распространения, — персональные данные, доступ неограниченного
            круга лиц к которым предоставлен субъектом персональных данных путем
            дачи согласия на обработку персональных данных, разрешенных
            субъектом персональных данных для распространения в порядке,
            предусмотренном Законом о персональных данных (далее — персональные
            данные, разрешенные для распространения).
            <br />
            2.10. Пользователь — любой посетитель веб-сайта https://talkvio.com.
            <br />
            2.11. Предоставление персональных данных — действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц.
            <br />
            2.12. Распространение персональных данных — любые действия,
            направленные на раскрытие персональных данных неопределенному кругу
            лиц (передача персональных данных) или на ознакомление с
            персональными данными неограниченного круга лиц, в том числе
            обнародование персональных данных в средствах массовой информации,
            размещение в информационно-телекоммуникационных сетях или
            предоставление доступа к персональным данным каким-либо иным
            способом.
            <br />
            2.13. Трансграничная передача персональных данных — передача
            персональных данных на территорию иностранного государства органу
            власти иностранного государства, иностранному физическому или
            иностранному юридическому лицу.
            <br />
            2.14. Уничтожение персональных данных — любые действия, в результате
            которых персональные данные уничтожаются безвозвратно с
            невозможностью дальнейшего восстановления содержания персональных
            данных в информационной системе персональных данных и/или
            уничтожаются материальные носители персональных данных.
            <br />
            3. Основные права и обязанности Оператора
            <br />
            3.1. Оператор имеет право:
            <br />— получать от субъекта персональных данных достоверные
            информацию и/или документы, содержащие персональные данные;
            <br />— в случае отзыва субъектом персональных данных согласия на
            обработку персональных данных, а также, направления обращения с
            требованием о прекращении обработки персональных данных, Оператор
            вправе продолжить обработку персональных данных без согласия
            субъекта персональных данных при наличии оснований, указанных в
            Законе о персональных данных;
            <br />— самостоятельно определять состав и перечень мер, необходимых
            и достаточных для обеспечения выполнения обязанностей,
            предусмотренных Законом о персональных данных и принятыми в
            соответствии с ним нормативными правовыми актами, если иное не
            предусмотрено Законом о персональных данных или другими федеральными
            законами.
            <br />
            3.2. Оператор обязан:
            <br />— предоставлять субъекту персональных данных по его просьбе
            информацию, касающуюся обработки его персональных данных;
            <br />— организовывать обработку персональных данных в порядке,
            установленном действующим законодательством РФ;
            <br />— отвечать на обращения и запросы субъектов персональных
            данных и их законных представителей в соответствии с требованиями
            Закона о персональных данных;
            <br />— сообщать в уполномоченный орган по защите прав субъектов
            персональных данных по запросу этого органа необходимую информацию в
            течение 10 дней с даты получения такого запроса;
            <br />— публиковать или иным образом обеспечивать неограниченный
            доступ к настоящей Политике в отношении обработки персональных
            данных;
            <br />— принимать правовые, организационные и технические меры для
            защиты персональных данных от неправомерного или случайного доступа
            к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных
            неправомерных действий в отношении персональных данных;
            <br />— прекратить передачу (распространение, предоставление,
            доступ) персональных данных, прекратить обработку и уничтожить
            персональные данные в порядке и случаях, предусмотренных Законом о
            персональных данных;
            <br />— исполнять иные обязанности, предусмотренные Законом о
            персональных данных.
            <br />
            4. Основные права и обязанности субъектов персональных данных
            <br />
            4.1. Субъекты персональных данных имеют право:
            <br />— получать информацию, касающуюся обработки его персональных
            данных, за исключением случаев, предусмотренных федеральными
            законами. Сведения предоставляются субъекту персональных данных
            Оператором в доступной форме, и в них не должны содержаться
            персональные данные, относящиеся к другим субъектам персональных
            данных, за исключением случаев, когда имеются законные основания для
            раскрытия таких персональных данных. Перечень информации и порядок
            ее получения установлен Законом о персональных данных;
            <br />— требовать от оператора уточнения его персональных данных, их
            блокирования или уничтожения в случае, если персональные данные
            являются неполными, устаревшими, неточными, незаконно полученными
            или не являются необходимыми для заявленной цели обработки, а также
            принимать предусмотренные законом меры по защите своих прав;
            <br />— выдвигать условие предварительного согласия при обработке
            персональных данных в целях продвижения на рынке товаров, работ и
            услуг;
            <br />— на отзыв согласия на обработку персональных данных, а также,
            на направление требования о прекращении обработки персональных
            данных;
            <br />— обжаловать в уполномоченный орган по защите прав субъектов
            персональных данных или в судебном порядке неправомерные действия
            или бездействие Оператора при обработке его персональных данных;
            <br />— на осуществление иных прав, предусмотренных
            законодательством РФ.
            <br />
            4.2. Субъекты персональных данных обязаны:
            <br />— предоставлять Оператору достоверные данные о себе;
            <br />— сообщать Оператору об уточнении (обновлении, изменении)
            своих персональных данных.
            <br />
            4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо
            сведения о другом субъекте персональных данных без согласия
            последнего, несут ответственность в соответствии с законодательством
            РФ.
            <br />
            5. Принципы обработки персональных данных
            <br />
            5.1. Обработка персональных данных осуществляется на законной и
            справедливой основе.
            <br />
            5.2. Обработка персональных данных ограничивается достижением
            конкретных, заранее определенных и законных целей. Не допускается
            обработка персональных данных, несовместимая с целями сбора
            персональных данных.
            <br />
            5.3. Не допускается объединение баз данных, содержащих персональные
            данные, обработка которых осуществляется в целях, несовместимых
            между собой.
            <br />
            5.4. Обработке подлежат только персональные данные, которые отвечают
            целям их обработки.
            <br />
            5.5. Содержание и объем обрабатываемых персональных данных
            соответствуют заявленным целям обработки. Не допускается
            избыточность обрабатываемых персональных данных по отношению к
            заявленным целям их обработки.
            <br />
            5.6. При обработке персональных данных обеспечивается точность
            персональных данных, их достаточность, а в необходимых случаях и
            актуальность по отношению к целям обработки персональных данных.
            Оператор принимает необходимые меры и/или обеспечивает их принятие
            по удалению или уточнению неполных или неточных данных.
            <br />
            5.7. Хранение персональных данных осуществляется в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных. Обрабатываемые персональные
            данные уничтожаются либо обезличиваются по достижении целей
            обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено федеральным законом.
            <br />
            6. Цели обработки персональных данных
            <br />
            Цель обработки информирование Пользователя посредством отправки
            электронных писем
            <br />
            Персональные данные
            <br />
            фамилия, имя, отчество
            <br />
            электронный адрес
            <br />
            фотографии
            <br />
            Правовые основания
            <br />
            уставные (учредительные) документы Оператора
            <br />
            Виды обработки персональных данных
            <br />
            Сбор, запись, систематизация, накопление, хранение, уничтожение и
            обезличивание персональных данных
            <br />
            7. Условия обработки персональных данных
            <br />
            7.1. Обработка персональных данных осуществляется с согласия
            субъекта персональных данных на обработку его персональных данных.
            <br />
            7.2. Обработка персональных данных необходима для достижения целей,
            предусмотренных международным договором Российской Федерации или
            законом, для осуществления возложенных законодательством Российской
            Федерации на оператора функций, полномочий и обязанностей.
            <br />
            7.3. Обработка персональных данных необходима для осуществления
            правосудия, исполнения судебного акта, акта другого органа или
            должностного лица, подлежащих исполнению в соответствии с
            законодательством Российской Федерации об исполнительном
            производстве.
            <br />
            7.4. Обработка персональных данных необходима для исполнения
            договора, стороной которого либо выгодоприобретателем или
            поручителем по которому является субъект персональных данных, а
            также для заключения договора по инициативе субъекта персональных
            данных или договора, по которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем.
            <br />
            7.5. Обработка персональных данных необходима для осуществления прав
            и законных интересов оператора или третьих лиц либо для достижения
            общественно значимых целей при условии, что при этом не нарушаются
            права и свободы субъекта персональных данных.
            <br />
            7.6. Осуществляется обработка персональных данных, доступ
            неограниченного круга лиц к которым предоставлен субъектом
            персональных данных либо по его просьбе (далее — общедоступные
            персональные данные).
            <br />
            7.7. Осуществляется обработка персональных данных, подлежащих
            опубликованию или обязательному раскрытию в соответствии с
            федеральным законом.
            <br />
            8. Порядок сбора, хранения, передачи и других видов обработки
            персональных данных
            <br />
            Безопасность персональных данных, которые обрабатываются Оператором,
            обеспечивается путем реализации правовых, организационных и
            технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства в области защиты
            персональных данных.
            <br />
            8.1. Оператор обеспечивает сохранность персональных данных и
            принимает все возможные меры, исключающие доступ к персональным
            данным неуполномоченных лиц.
            <br />
            8.2. Персональные данные Пользователя никогда, ни при каких условиях
            не будут переданы третьим лицам, за исключением случаев, связанных с
            исполнением действующего законодательства либо в случае, если
            субъектом персональных данных дано согласие Оператору на передачу
            данных третьему лицу для исполнения обязательств по
            гражданско-правовому договору.
            <br />
            8.3. В случае выявления неточностей в персональных данных,
            Пользователь может актуализировать их самостоятельно, путем
            направления Оператору уведомление на адрес электронной почты
            Оператора degx@mail.ru с пометкой «Актуализация персональных
            данных».
            <br />
            8.4. Срок обработки персональных данных определяется достижением
            целей, для которых были собраны персональные данные, если иной срок
            не предусмотрен договором или действующим законодательством.
            <br />
            Пользователь может в любой момент отозвать свое согласие на
            обработку персональных данных, направив Оператору уведомление
            посредством электронной почты на электронный адрес Оператора
            degx@mail.ru с пометкой «Отзыв согласия на обработку персональных
            данных».
            <br />
            8.5. Вся информация, которая собирается сторонними сервисами, в том
            числе платежными системами, средствами связи и другими поставщиками
            услуг, хранится и обрабатывается указанными лицами (Операторами) в
            соответствии с их Пользовательским соглашением и Политикой
            конфиденциальности. Субъект персональных данных и/или с указанными
            документами. Оператор не несет ответственность за действия третьих
            лиц, в том числе указанных в настоящем пункте поставщиков услуг.
            <br />
            8.6. Установленные субъектом персональных данных запреты на передачу
            (кроме предоставления доступа), а также на обработку или условия
            обработки (кроме получения доступа) персональных данных, разрешенных
            для распространения, не действуют в случаях обработки персональных
            данных в государственных, общественных и иных публичных интересах,
            определенных законодательством РФ.
            <br />
            8.7. Оператор при обработке персональных данных обеспечивает
            конфиденциальность персональных данных.
            <br />
            8.8. Оператор осуществляет хранение персональных данных в форме,
            позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения
            персональных данных не установлен федеральным законом, договором,
            стороной которого, выгодоприобретателем или поручителем по которому
            является субъект персональных данных.
            <br />
            8.9. Условием прекращения обработки персональных данных может
            являться достижение целей обработки персональных данных, истечение
            срока действия согласия субъекта персональных данных, отзыв согласия
            субъектом персональных данных или требование о прекращении обработки
            персональных данных, а также выявление неправомерной обработки
            персональных данных.
            <br />
            9. Перечень действий, производимых Оператором с полученными
            персональными данными
            <br />
            9.1. Оператор осуществляет сбор, запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление и уничтожение персональных
            данных.
            <br />
            9.2. Оператор осуществляет автоматизированную обработку персональных
            данных с получением и/или передачей полученной информации по
            информационно-телекоммуникационным сетям или без таковой.
            <br />
            10. Трансграничная передача персональных данных
            <br />
            10.1. Оператор до начала осуществления деятельности по
            трансграничной передаче персональных данных обязан уведомить
            уполномоченный орган по защите прав субъектов персональных данных о
            своем намерении осуществлять трансграничную передачу персональных
            данных (такое уведомление направляется отдельно от уведомления о
            намерении осуществлять обработку персональных данных).
            <br />
            10.2. Оператор до подачи вышеуказанного уведомления, обязан получить
            от органов власти иностранного государства, иностранных физических
            лиц, иностранных юридических лиц, которым планируется трансграничная
            передача персональных данных, соответствующие сведения.
            <br />
            11. Конфиденциальность персональных данных
            <br />
            Оператор и иные лица, получившие доступ к персональным данным,
            обязаны не раскрывать третьим лицам и не распространять персональные
            данные без согласия субъекта персональных данных, если иное не
            предусмотрено федеральным законом.
            <br />
            12. Заключительные положения
            <br />
            12.1. Пользователь может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись
            к Оператору с помощью электронной почты degx@mail.ru.
            <br />
            12.2. В данном документе будут отражены любые изменения политики
            обработки персональных данных Оператором. Политика действует
            бессрочно до замены ее новой версией.
            <br />
            12.3. Актуальная версия Политики в свободном доступе расположена в
            сети Интернет по адресу https://talkvio.com/policy.
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default SpecialPage;
